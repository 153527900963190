<div class="google-review relative">
    <div *ngIf="this.location === 'subscription'">
        <h2 class="text-2xl sm:text-4xl text-gray-900 text-center font-bold">
            Hear From Real Users
        </h2>
    </div>
    <div *ngIf="this.location === 'footer'" class="flex flex-col md:flex-row justify-center md:items-center relative">
        <h2 class="text-2xl text-gray-900 font-semibold text-center md:text-left">Loved by our customers</h2>
        <div class="flex gap-2 justify-center md:justify-start items-center md:absolute md:right-0">
            <div class="relative flex items-center">
                <img src="https://imgix.cosmicjs.com/6ed174a0-e55b-11ef-b24e-55b3c3cc5f08-flat-color-iconsgoogle.svg?auto=format,compresss&w=60" alt="img" width="40" height="40"  class="rounded-full bg-white p-1 shadow">
                <img src="https://imgix.cosmicjs.com/96c218a0-e503-11ef-b24e-55b3c3cc5f08-DollarSticker.png?auto=format,compress&w=60" alt="img" width="40" height="40" class="-ml-3 rounded-full bg-white p-1 shadow">
                <img src="https://imgix.cosmicjs.com/96bd0f90-e503-11ef-b24e-55b3c3cc5f08-6bed8ef7-afc0-4ea2-9e3b-e026fde212fe.png?auto=format,compress&w=60" alt="img" width="40" height="40" class="-ml-3 rounded-full bg-white p-1 shadow">
            </div>
            <div>
                <div>
                    <span class="mr-2 text-base font-medium text-900">4.8</span> 
                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                </div>
                <div class="text-base font-medium text-900">
                    Google Reviews
                </div>
            </div>
        </div>
    </div>
    <div class="carousel-container p-6 pt-0 sm:p-8 sm:pt-0">
        <div class="embla__viewport overflow-hidden p-2">
            <div class="embla" emblaCarousel [options]="productsSliderOptions"  [subscribeToEvents]="subscribeToEvents" (emblaChange)="onEmblaChanged($event, emblaRef().emblaApi! )">
                <div class="embla__container">
                    @for (slide of reviews; track slide) {
                        <div class="embla__slide">
                            <div  class="embla__slide__review overflow-hidden rounded-xl border border-gray-200 shadow shadow-gray-200 p-6 flex flex-col gap-4 h-full">
                                <div>
                                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                                    <fa-icon [icon]="faStar" class="text-yellow-500"></fa-icon>
                                </div>
                                <div class="text-sm font-medium leading-5">
                                    {{ slide.comments }}
                                </div>
                                <div class="flex gap-2 items-center mt-auto">
                                    <div class="reviewer-profile">
                                        <img [src]="slide.image_url" alt="img" width="52" height="52">
                                    </div>
                                    <div class="reviewer-name text-base font-semibold">
                                        {{slide.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                </div>
            </div>
        </div>
        
        <div class="embla__dots flex flex-wrap justify-center items-center">
            @for (item of scrollSnaps(); track item) {
                <button 
                    class="embla__dot"
                    [ngClass]="'embla__dot'.concat($index === selectedIndex() ? ' embla__dot--selected' : '')"
                    type="button"
                    (click)="scrollTo($index)"></button>
            }
        </div>
        <div class="carousel-prev-button w-10 absolute left-0 top-1/2 -translate-y-1/2 cursor-pointer z-10 flex justify-center items-center"
            (click)="prevSlide()">
            <fa-icon [icon]="faChevronLeft" class="carousel-nav-icon"></fa-icon>
        </div>
        <div class="carousel-next-button w-10 absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer z-10 flex justify-center items-center"
            (click)="nextSlide()">
            <fa-icon [icon]="faChevronRight" class="carousel-nav-icon"></fa-icon>
        </div>
    </div>

</div>