import { Component, OnInit } from '@angular/core';
import { NgIf, NgFor, AsyncPipe, NgOptimizedImage } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faStar } from'@fortawesome/free-solid-svg-icons';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { Observable, map, shareReplay } from 'rxjs';

@Component({
    selector: 'app-black-friday-banner',
    standalone: true,
    imports: [
        FontAwesomeModule,
        NgIf,
        NgFor,
        RouterLink,
        AsyncPipe,
        NgOptimizedImage,
    ],
    templateUrl: './black-friday-banner.component.html',
    styleUrl: './black-friday-banner.component.scss'
})
export class BlackFridayBannerComponent implements OnInit {
    faStar = faStar;
    faTimes = faTimes;
    faChevronRight = faChevronRight;

    bannerVisible: boolean = true;
    hiddenRoutes: boolean = true;

    animated_carousel = [
        "“I love this site, they not only give you a fish, but they teach you how to fish”",
        "“Dimers feels like having a secret weapon”", 
        "“Dimers turned me from a newbie to a pro literally overnight”",
        "“I don't go a day without checking Dimers”",
        "“This site is a game-changer you didn't know you needed”",
    ]

    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro
		}),
		shareReplay(1)
	);

    constructor(
        private router: Router,
        private authHelper: AuthHelperService,
        
	) {}

    ngOnInit(): void {
        console.log(this.router.url);
        // initial check of visibility for route session begins on
        this.checkVisibility(this.router.url || "");

        // subsequent check of visibility on each change of page
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.checkVisibility(event.urlAfterRedirects)
        });
    }

    checkVisibility(route: string): void {
        if (
            route.includes('/news/author') ||
            route.includes('/privacy-policy') ||
            route.includes('/terms-of-service') ||
            route.includes('/sitemap') ||
            route.includes('/editorial-guidelines') ||
            route.includes('/responsible-gambling') ||
            route.includes('/business') ||
            route.includes('/best-books') ||
            route === '/subscription' ||
            route === '/super-bowl'
        ) {
            this.hiddenRoutes = false;
        } else {
            this.hiddenRoutes = true;
        }
    }

    closeBanner() {
        this.bannerVisible = false;
    }
}
