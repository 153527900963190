export const BOOKMAKERS: Array<string> = [
	"fanduel",
	"betmgm",
	// "twinspires",
	// "pointsbet",
	"william_hill",
	"draftkings",
	// "wynn",
	// "betway",
	// "tipico",
	// "bet365",
	"bet_365",
	// "unibet",
	"bet_rivers_in"
]