import { /*DOCUMENT,*/ isPlatformBrowser, NgFor } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID, ElementRef, ViewChild } from '@angular/core';
import { Sport } from 'src/app/sport.model';
import { SPORTS } from 'src/app/sports';
import { LocalisationService } from 'src/app/localisation.service';
// import { BlogService } from 'src/app/blog.service';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { SportIconComponent } from '../../sport-icon/sport-icon.component';

@Component({
  selector: 'app-sub-footer',
  templateUrl: './sub-footer.component.html',
  styleUrls: ['./sub-footer.component.scss'],
  standalone: true,
  imports: [NgFor, SportIconComponent, RouterLink]
})
export class SubFooterComponent implements OnInit {

	sports: Array<Sport>;
	predictionLinks = [];
	bestBetsLinks = [];
	bestPropsLinks = [];
    parlayPickers=[];
	futuresLinks = [];
	oddsLinks = [];
	newsLinks = [];

	bestBooksState = "";
	browserMode = false;
	bestBooksError = false;
	bestBooksURL: string = "/best-books";
	bestBooksOptions: Array<{
		name: string;
		link: string;
		icon: string;
	}> = [];
	bestBooksLoading = true;
	currentRoute: string='';
	defaultShowLinks: boolean = false;
	@ViewChild("subLinkTabs") subLinkTabs:ElementRef<HTMLElement>;
	@ViewChild("subLinkItems") subLinkItems:ElementRef<HTMLElement>;
	activeSubFooterCategory = "";

	subFooterCategories = ['schedule', 'best-bets','best-props', 'best-trends', /*'best-books',*/ 'futures', 'odds', 'sweepstakes', 'news', 'articles', /*'super-bowl',*/ 'subscription'];

    // sportsbooks = [];
    // dfsProviders = [];

    constructor(
        private localisationService: LocalisationService,
        @Inject(PLATFORM_ID) platformId: string,
        // private blogService: BlogService,
        private router: Router,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
    }

	ngOnInit(): void {
		this.sports = this.localisationService.sportPrioritySort(SPORTS.filter(s => !s.hideFromMainNav));

		if (this.sports.length > 0) {
			for ( let sport of this.sports) {
				for ( let link of sport.navigablePages) {
					let	obj = {
						code: sport.code,
						shortName: sport.shortName,
						path: link.path,
						queryParams: link.queryParams ? link.queryParams : ""
					}
					switch (link.name)
					{
						case "Predictions":
							this.predictionLinks.push(obj);
							break;
						case "Best Bets":
							this.bestBetsLinks.push(obj);
							break;
						case "Best Props":
							this.bestPropsLinks.push(obj);
							break;
                        case "Parlays":
                            this.parlayPickers.push(obj);
                            break;
						case "Futures":
							this.futuresLinks.push(obj);
							break;
						case "Odds":
							this.oddsLinks.push(obj);
							break;
						case "News":
							this.newsLinks.push(obj);
					}
				}
			}
		}


		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {

				this.currentRoute = event.url;

				//find the link text between slash
				const matchedAry = this.currentRoute.match(/\/.*\//);
				if( matchedAry !== null)
				this.currentRoute = matchedAry[0];

				this.activeSubFooterCategory = "";

				//find if the page is one of the sports categories
				for (let item of this.subFooterCategories) {
					if (this.currentRoute.includes(item)) {
						//if yes, add active status to tab and links

						this.activeSubFooterCategory = item;
					}
				}
				if (!this.activeSubFooterCategory) {
					//if the page is not one of the sports categories, add active to first item
					this.activeSubFooterCategory = this.subFooterCategories[0];
				}
			}
		});

        // this.getSportsBookReviewLinks();
        // this.getDFSReviewLinks();
	}


	toggleShow(category: string) {
		this.activeSubFooterCategory = category;
	}

    // getSportsBookReviewLinks() {
	// 	this.blogService.sportsBookReviewLinks$.subscribe((reviewLinks) => this.postBookReviewLinksFetch(reviewLinks), () => {
	// 		this.sportsbooks = [];
	// 	});
    // }

	// postBookReviewLinksFetch(reviewLinks: Array<Record<string, any>>): void {
	// 	this.sportsbooks = reviewLinks;
	// }

    // getDFSReviewLinks() {
	// 	this.blogService.dfsReviewLinks$.subscribe((reviewLinks) => this.postDFSReviewLinksFetch(reviewLinks), () => {
	// 		this.dfsProviders = [];
	// 	});
	// }

	// postDFSReviewLinksFetch(reviewLinks: Array<Record<string, any>>) {
	// 	this.dfsProviders = reviewLinks;
	// }

}
