import { DOCUMENT, NgIf, NgClass, NgFor, AsyncPipe, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { RouterLink } from '@angular/router';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { Observable, map, shareReplay } from 'rxjs';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BlogService } from 'src/app/blog.service';
import { Sport } from 'src/app/sport.model';
import { SportIconComponent } from '../sport-icon/sport-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { faBell } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-mobile-navigation',
    templateUrl: './mobile-navigation.component.html',
    styleUrls: ['./mobile-navigation.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, FontAwesomeModule, NgClass, NgFor, SportIconComponent, AsyncPipe]
})
export class MobileNavigationComponent {

    isBrowser: boolean;
	mobileNavVisible = false;
	accountDropdownOpen = false;
	@Input() dropdownSports: Array<Sport>;
	@Input() otherSports: Array<Sport>;
	@Input() liveLightVisible: boolean;
	@Input() fireNewsletterPopUp: () => void;
	moreDropdownOpen = false;
    navMoreSidebar = false;
    navSportsSidebar = false;
	faChevronRight = faChevronRight;
	faChevronDown = faChevronDown;
    // faBell = faBell;
    navProSidebar = false;

    navInteracted = false;

    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro
		}),
		shareReplay(1)
	);
    
    affiliate$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_affiliate
		}),
		shareReplay(1)
	);

	constructor(
		@Inject(DOCUMENT) public document: Document,
		public authHelper: AuthHelperService,
		private gtmService: GoogleTagManagerService,
		private blogService: BlogService,
        @Inject(PLATFORM_ID) platformId: object,
	) {
        this.isBrowser = isPlatformBrowser(platformId);
	}
	
	toggleAccountDropdown(): void {
        this.navInteracted = true;
		this.accountDropdownOpen = !this.accountDropdownOpen;
		this.moreDropdownOpen = false;
	}
	
	hideAccountDropdown(): void {
		this.accountDropdownOpen = false;
	}

    toggleNavMoreSidebar(): void {
        this.navInteracted = true;
        this.navSportsSidebar = false;
		this.accountDropdownOpen = false;
        this.navProSidebar= false;
		this.navMoreSidebar = !this.navMoreSidebar;
	}

    toggleNavProSidebar(): void {
        this.navInteracted = true;
        this.navSportsSidebar = false;
		this.accountDropdownOpen = false;
        this.navMoreSidebar = false;
        this.navProSidebar = !this.navProSidebar;
	}

    toggleNavSportsSidebar(): void {
        this.navInteracted = true;
        this.navMoreSidebar = false;
        this.navProSidebar= false;
        this.navSportsSidebar = !this.navSportsSidebar;
		this.accountDropdownOpen = false;
    }

    hideAllOpenMenuLinks(): void {
        this.navMoreSidebar = false;
        this.navSportsSidebar = false;
    }

    @HostListener('window:resize',['$event']) onResize(e: MouseEvent) {
        this.hideAllOpenMenuLinks();
	}

	hideMoreDropdown(): void {
		this.moreDropdownOpen = false;
	}

	sendTapTag(pageName: string): void {
		const gtmTag = {
			event: "tapped",
			event_category: "Mobile Menu",
			event_label: pageName,
		};
	  
		this.gtmService.pushTag(gtmTag)
			.catch(e => {
				console.error("Google Tag Manager Script failed: " + e)
			});
	}
    
    selectTab(tab: string) {
        this.blogService.setSelectedMyaccountTab(tab);
    }

    openProModal() {
        // Open Pro modal
        this.blogService.setProModalData(true);
    }

}
