import { Injectable } from '@angular/core';
import { Sport } from './sport.model';

interface LocaleObject {
	oddsDisplayFormat: "decimal" | "american";
	sportPriorityOrder: Array<string>;
	blockLocaleCode: string;
	termDictionary: Record<string, string>;
	hiddenSports: Array<string>;
	highlightedMatchIDs: Array<string>;
	sportExclusiveBookmakers: Record<string, string>;
	highlightedTeams: Record<string, Array<string>>;
}

@Injectable({
	providedIn: 'root'
})
export class LocalisationService {
	public locale = "US";
	localeOptions: Record<string, LocaleObject> = {
		"US": {
			oddsDisplayFormat: "american",
			sportPriorityOrder: ["NFL", "NBA", "WNBA", "MLB", "NHL", "MLS"],
			blockLocaleCode: "US",
			hiddenSports: ["SOO", "BBL", "ALG", "AFLW", "RACING", "SRU", "TRC"],
			highlightedMatchIDs: [],
			sportExclusiveBookmakers: {},
			termDictionary: {
				match: "game",
				match_tennis: "match",
				matches: "games",
				matches_tennis: "matches",
				prematch: "pre-game",
				prematch_tennis: "pre-match",
				fixture: "schedule",
				fixtures: "schedule",
				value_picks: "best bets",
				prop_picks: "prop bets",
				sidebar_title: "upcoming",
				h2h: "Moneyline",
				h2h_short: "Moneyline",
				line: "Spread",
				line_nhl: "Puck Line",
				line_mlb: "Run Line",
				line_short: "Spread",
				game_line: "Game Spread",
				total: "Over / Under",
				total_short: "O/U",
				firstset: "First Set",
				firstset_short: "1st Set",
				shortlist: "favorites",
				shortlist_adjective: "shortlisted",
				my_shortlist: "My favorites",
				no_shortlisted: "No shortlisted games available",
				no_live: "No live games available",
				bts: "Both Teams to Score",
				bts_short: "BTS",
				bet_button_cta: "Bet Now",
			},
			highlightedTeams: {}
		}
	}
	constructor() { }
	setLocale(l: string): void {
		if (Object.keys(this.localeOptions).includes(l)) {
			this.locale = l;
		}
	}
	
	getLocaleObject(): LocaleObject {
		return this.localeOptions[this.locale];
	}
	
	convertDecimalOdds(odds: number | string): string {
		if (typeof odds === "string") {
			if (!isNaN(parseFloat(odds))) {
				odds = parseFloat(odds);
			} else {
				return "-"
			}
		}
		
		if (this.localeOptions[this.locale].oddsDisplayFormat === "decimal") {
			return `${odds.toFixed(2)}`;
		}
		
		if (this.localeOptions[this.locale].oddsDisplayFormat === "american") {
			if (odds < 2) {
				const minusNumber = 100 / (odds - 1);
				return "-" + minusNumber.toFixed(0);
			}
			
			const plusNumber = (odds * 100) - 100;
			return "+" + plusNumber.toFixed(0);
		}
		
		// TODO fractional
		return "";
	}
	
	sportPrioritySort(sports: Array<Sport>) {
		return sports.slice()
			.filter(s => !this.getLocaleObject().hiddenSports.includes(s.code))
			.sort((a,b) => {
				// if both sports are in the current locale's priority list, put the one earlier in the priority list ahead
				if (this.getLocaleObject().sportPriorityOrder.includes(a.code)
				&& this.getLocaleObject().sportPriorityOrder.includes(b.code)) {
					return this.getLocaleObject().sportPriorityOrder.indexOf(a.code) - this.getLocaleObject().sportPriorityOrder.indexOf(b.code);
				}
				
				// if exactly one of the two sports is in the current locale's priority list, put the one that's there ahead
				if (this.getLocaleObject().sportPriorityOrder.includes(a.code)) {
					return -1;
				}
				
				if (this.getLocaleObject().sportPriorityOrder.includes(b.code)) {
					return 1;
				}
				
				// otherwise, sort them by position in the original list of sports
				return sports.indexOf(a) - sports.indexOf(b);
			});
	}
	
	localeTerm(term: string, modification?: string): string {
		let retrievedTerm: string;
		if (Object.keys(this.getLocaleObject().termDictionary).includes(term)) {
			retrievedTerm = this.getLocaleObject().termDictionary[term];
		} else {
			// TODO decide if there's a better way to handle unrecognised messages (throw error, show message in place of term)
			retrievedTerm = term;
		}
		
		if (modification === "all-caps") {
			return retrievedTerm.toUpperCase();
		} else if (modification === "title-case") {
			return retrievedTerm.replace(/\b\w+/g, s => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase());
		} else {
			return retrievedTerm;
		}
	}
}
