<div class="bf-banner" *ngIf="bannerVisible && hiddenRoutes">
    <div class="bg-highlight-100 py-2.5 relative top-section">
        <div class="grid grid-cols-[auto_1fr] md:grid-cols-[20%_60%_20%] items-center max-w-[1270px] xl:px-2.5 mx-auto gap-x-4 md:gap-x-3">
            <div class="col-start-1 col-span-1 row-span-2 md:row-span-1">
                <img class="max-sm:w-14" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                    alt="Dimers pro" width="80" height="80" loading="lazy"
                >
            </div>
            <div class="text-white text-sm md:text-lg font-semibold leading-6 promo-text col-start-2 col-span-1 text-start md:text-center">
                @if((proUser$ | async) === false) {
                    ENDS SOON: GET 1 MONTH FOR THE PRICE OF 1 WEEK
                }
                @else if((proUser$ | async) === true) {
                    Introducing College Basketball Player Projections
                }@else {
                    
                }
            </div>
            @if((proUser$ | async) === false) {
                <button id="holiday-banner-promo-btn" class="text-white underline text-sm md:text-lg font-semibold flex items-center md:justify-end bf-promo-btn gap-1 whitespace-nowrap col-start-2 md:col-start-3 col-span-1" routerLink="/subscription">
                    Claim now
                    <fa-icon [icon]="faChevronRight" class="texta-white flex"></fa-icon>
                </button>
            }@else if((proUser$ | async) === true){
                <button id="holiday-banner-promo-btn" class="text-white underline text-sm md:text-lg font-semibold flex items-center md:justify-end bf-promo-btn gap-1 whitespace-nowrap col-start-2 md:col-start-3 col-span-1" routerLink="/cbb/player-projections">
                    See projections
                    <fa-icon [icon]="faChevronRight" class="texta-white flex"></fa-icon>
                </button>
            }

            <div id="holiday-banner-close-btn" class="absolute top-1 right-3 cursor-pointer text-white hover:bg-gray-600 flex items-center justify-center rounded-full w-7 h-7 delay-150" (click)="closeBanner()">
                <fa-icon class="text-lg flex" [icon]="faTimes"></fa-icon>
            </div>
        </div>
    </div>
</div>