export const environment = {
	production: true,
	gaKey: "UA-173939745-1",
	gtmKey: "GTM-PXBM76X",
    captchaKey: "6Lf3QCQlAAAAAGkytf0KNgciduMS8Jyki1V-k8ql",
	apiDomain: "https://levy-edge.statsinsider.com.au",
	apiOddsDomain: "https://levy-odds.statsinsider.com.au",
	blockDomain: "https://content-blocks-node.azurewebsites.net",
	dimersApiDomain: "https://cdn.dimers.com",
	dimersUncachedDomain: "https://api.dimers.com",
	dimersGeoDomain: "https://services.dimers.com",
	sharpSportsKey: "e0692325b2e0e30257751f10b3d5d310e4327a2d",
	cosmicApiDomain: "https://cosmic-proxy.statsinsider.com.au/api/v3/buckets/",
	cosmicUncachedDomain: "https://api.cosmicjs.com/v3/buckets/",
	dimersProApi: "https://levy-next.statsinsider.com.au/subs",
	dimersProPriceMap: {
		"weekly": "price_1Qm6ZPDTA6gMH5jusmchzFYt",
		"monthly": "price_1Qm6a7DTA6gMH5jupxXfoBRT",
		"yearly": "price_1Qm6b2DTA6gMH5jumVtlshmM",
	},
	honeycombFrontendKey: "hcaik_01jbd5sfmb7174zh86yz1jyjapf3ma9wmb3v6azyw5paa2qxbcp8sf3cfv"
};
