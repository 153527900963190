<!-- <div *ngIf="selectedFrequency === 'yearly'">
	<span class="text-sm font-medium text-white px-3 py-1 rounded bg-highlight-100 max-w-max">
		EARLY BIRD
	</span>
</div>

<div *ngIf="selectedFrequency === 'yearly'" class="text-xl font-semibold text-AIDimers-black flex items-center gap-2 flex-wrap">   
	<img loading="lazy" src="https://imgix.cosmicjs.com/9a4e0b30-d1f6-11ee-babb-b99b48a02ac8-dimers-pro-logo.svg" alt="icon" height="32" width="32">
	Dimers Pro 
	<span>“SWISH100”</span>
</div> -->

<div class="shadow rounded-3xl border-0 p-3 md:p-6 bg-gradient-to-b from-AIDimers-grey to-white" *ngIf="selectedFrequency !== 'monthly'">
	<div class="flex justify-center items-center">
		<img src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-8 w-8" height="32" width="32"
			alt="logo">
	</div>
	<div class="text-highlight-100 font-semibold text-base md:text-lg text-center">
		Don't miss out.
	</div>
	<div class="text-sm md:text-base font-semibold leading-6 text-center">
		Dimers Pro users get access to an average of 200 bets per day
	</div>
</div>
<div class="bg-gradient-to-b from-AIDimers-grey border border-AIDimers-grey rounded-3xl p-4 flex flex-col items-center" *ngIf="selectedFrequency === 'monthly'">
    <img src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" alt="Dimers pro" width="32" height="32" loading="lazy">
    <div class="text-highlight-100 font-semibold text-base md:text-lg text-center">
		Don't miss out.
	</div>
	<div class="promotion-text text-base text-gray-900 font-semibold leading-6 text-center mt-2" *ngIf="selectedFrequency === 'monthly'">
		ENDS SOON: GET 1 MONTH FOR THE PRICE OF 1 WEEK
	</div>
	<!-- <div class="promotion-text text-lg text-gray-900 font-semibold leading-6 text-center" *ngIf="selectedFrequency !== 'monthly'">
		Pay $10 a month for 3 months instead. <span class="text-highlight-100 underline cursor-pointer" (click)="setFrequency('monthly')">See Monthly</span>
	</div> -->
</div>
<!-- black friday design -->
<!-- <img class="inline w-full h-auto" alt="Dimers Pro" src="https://cdn.ciphersports.io/assets/dimers-pro-full-logo.svg" height="211" width="1240"> -->
<!-- Frequency toggle -->
<div class="frequency-options flex flex-row justify-center items-stretch gap-2">
	<div class="frequency-option flex-1 py-1 px-3 flex flex-col justify-center items-center rounded-sm text-sm leading-6"
		[class]="selectedFrequency === option ? 'bg-highlight-100 text-white' : 'cursor-pointer bg-white text-black'"
		*ngFor="let option of frequencyOptions"
		(click)="setFrequency(option)"
	>
		{{ option === 'yearly' ? 'Annual' : (option | titlecase) }}
	</div>
</div>
<!-- black friday design -->
<!-- <div class="bg-gradient-to-b from-AIDimers-grey border border-AIDimers-grey rounded-3xl p-4">
	<img src="https://imgix.cosmicjs.com/a7871210-b03f-11ef-8a74-0fd863afe214-Cyber-Monday-Sale-Logo.svg?auto=format,compress&w=500" alt="CYBER MONDAY SALE!" width="500" height="37" loading="lazy">
	<div class="promotion-text text-lg text-gray-900 font-semibold leading-6 text-center mt-2" *ngIf="selectedFrequency === 'monthly'">
		Get 3 months of Dimers Pro for the price of 1
	</div>
	<div class="promotion-text text-lg text-gray-900 font-semibold leading-6 text-center" *ngIf="selectedFrequency !== 'monthly'">
		Pay $10 a month for 3 months instead. <span class="text-highlight-100 underline cursor-pointer" (click)="setFrequency('monthly')">See Monthly</span>
	</div>
</div> -->
<!-- Price display -->
<div class="price-display flex flex-col justify-center items-stretch gap-1 text-AIDimers-black">
	<div class="price-line flex flex-col justify-center items-center gap-1">
		<span class="price-main text-3xl font-bold text-center">
			<ng-container *ngIf="selectedFrequency === 'weekly'">$9.99</ng-container>
			<ng-container *ngIf="selectedFrequency === 'monthly'">
				<span class="text-2xl font-bold line-through text-gray-600 inline" aria-hidden="true">$29.99</span>
				<span class="text-black"> $9.99</span>
			</ng-container>
			<ng-container *ngIf="selectedFrequency === 'yearly'">
				<span class="text-2xl font-bold line-through text-gray-600 inline" aria-hidden="true">$24.99</span>
				<span class="text-black"> $16.67</span>
			</ng-container>
		</span>
		<span class="price-note text-sm font-semibold">
			<ng-container *ngIf="selectedFrequency === 'weekly'">/billed weekly</ng-container>
			<ng-container *ngIf="selectedFrequency === 'monthly'">/for your first month</ng-container>
			<ng-container *ngIf="selectedFrequency === 'yearly'">/month, billed annually</ng-container>
		</span>
	</div>
	<div class="price-note text-sm font-semibold text-center">
		<ng-container *ngIf="selectedFrequency === 'weekly'">
			<span class="bg-blue-200 text-highlight-100 rounded block py-2">
				Flexibility at its best
			</span>
		</ng-container>
		<ng-container *ngIf="selectedFrequency === 'monthly'">
			<!-- <span class="bg-blue-200 text-highlight-100 rounded block py-2">
				Save 49% vs. weekly
			</span> -->
			<div class="flex items-center justify-center border-dashed border rounded-md py-2 border-black font-semibold text-gray-900 relative">CODE: MARCH    
				<button  (click)="copyCode()" cdkCopyToClipboard="MARCH" class="ml-3">
					<span id="default-icon flex flex-col" *ngIf="!startCopy">
						<fa-icon aria-hidden="true" [icon]="faCopy"></fa-icon>
					</span>
					<span id="success-icon" *ngIf="startCopy" class="inline-flex items-center text-green-600">
						<fa-icon aria-hidden="true" [icon]="faCheck"></fa-icon>
					</span>
				</button>
			</div>
			<!-- <span class="text-highlight-100 rounded block py-2">
				New customer offer
			</span> -->
		</ng-container>
		<ng-container *ngIf="selectedFrequency === 'yearly'">
			<span class="bg-blue-200 text-highlight-100 rounded block py-2">
				<!-- <span class="line-through text-gray-600" aria-hidden="true">$21.99</span> -->
				Save 24% vs. monthly
			</span>
			<!-- <div class="flex items-center justify-center border-dashed border rounded-md py-2 border-black font-semibold text-gray-900 relative h-9">CODE: SWISH100
				<button  (click)="copyCode()" cdkCopyToClipboard="SWISH100" class="ml-3">
					<span id="default-icon flex flex-col" *ngIf="!startCopy">
						<fa-icon aria-hidden="true" [icon]="faCopy"></fa-icon>
					</span>
					<span id="success-icon" *ngIf="startCopy" class="inline-flex items-center text-green-600">
						<fa-icon aria-hidden="true" [icon]="faCheck"></fa-icon>
					</span>
				</button>
			</div>
			<div class="mt-2 text-sm text-gray-900 font-semibold leading-5 text-left">
				To celebrate the start of the basketball season, get $100 off when you subscribe to annual. Limited time only.
			</div> -->
		</ng-container>
	</div>
</div>
<div class="sub-body block md:hidden">
	<div class="block md:hidden mt-2">
		<app-pro-comparison-table></app-pro-comparison-table>
	</div>
</div>
<a class="subscribe-button" routerLink="/pro-purchase/{{ selectedFrequency }}">
	Subscribe
</a>
<div class="trust-info">
	<div class="flex items-center justify-center gap-2">
		<fa-icon [icon]="faShieldCheck" class="text-green-600"></fa-icon>
		<span class="text-base font-semibold">Guaranteed safe & secure checkout</span>
	</div>
	<div class="flex items-center justify-center gap-2">
		<img src="https://imgix.cosmicjs.com/0209bc90-aac0-11ef-9da2-d1ed2e62fff4-Powered-by-Stripe---black-1.svg?auto=format,compress&w=300"
			width="150" height="20" alt="stripe" loading="lazy">
	</div>
</div>