import { NgIf, CommonModule } from '@angular/common';
import { Component, Input, NgZone, viewChild, ElementRef, signal, ChangeDetectionStrategy} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import {
    EmblaCarouselDirective,
    EmblaCarouselType,
    EmblaEventType
  } from 'embla-carousel-angular'

@Component({
  selector: 'app-google-review',
  standalone: true,
  imports: [
    EmblaCarouselDirective,
    FontAwesomeModule,
    CommonModule,
    NgIf
  ],
  templateUrl: './google-review.component.html',
  styleUrl: './google-review.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleReviewComponent {
    @Input() location:string;
    emblaRef = viewChild(EmblaCarouselDirective)
    subscribeToEvents: EmblaEventType[] = ['init', 'reInit', 'select', 'scroll']
    scrollSnaps = signal<number[]>([])
    prevBtnEnabled = signal(false)
    nextBtnEnabled = signal(false)
    selectedIndex = signal(0);

    dotsNode: HTMLElement;
    dotNodes: HTMLElement[] = [];
    faStar = faStar;
    faChevronLeft = faChevronLeft;
    faChevronRight = faChevronRight;
     
    reviews = [
        {
            comments:"This is real.  I joined for week 4.  I was conservative at first wanting verification of the concept.  PLATINUM ran 70% for the year. It was plus money 12 of 14 weeks.  The only caveat was the picks were averaging -124. I will absolutely be with them again next year.   -CHEF",
            image_url: "https://imgix.cosmicjs.com/6ed174a0-e55b-11ef-b24e-55b3c3cc5f08-flat-color-iconsgoogle.svg?auto=format,compress&w=78",
            name: "RICH FORD"
        },
        {
            comments:"Diners has so much info that you can just play one sport and really mind for gems to add to your ROI. Whether it is the cheat sheets or the college football platinum1st quarters or any of their other individualized categories, there is so much to chose from and make you handicapping/wagering explode to another level!!",
            image_url: "https://imgix.cosmicjs.com/96c218a0-e503-11ef-b24e-55b3c3cc5f08-DollarSticker.png?auto=format,compress&w=78",
            name: "Scott Carpenter"

        },
        {
            comments:"The entire platform has been exceptional. From their communication to customer service. Quick to chat and respond and have delivered some really good insights since joining in under a month.  Example here of just taking their NBA data and making good on a parlay. Results are there.",
            image_url: "https://imgix.cosmicjs.com/96bd0f90-e503-11ef-b24e-55b3c3cc5f08-6bed8ef7-afc0-4ea2-9e3b-e026fde212fe.png?auto=format,compress&w=78",
            name: "Adam Elledge"
        },
        {
            comments:"Best research tool in sports betting. Signed up with them and won over $1k in a week using the data they provided. Membership also comes with a dope discord community where everyone shares their insight. Best investment I've made all year",
            image_url: "https://imgix.cosmicjs.com/96a19850-e503-11ef-b24e-55b3c3cc5f08-0b1bf39b-56a5-42d4-815d-e5d19b00808d.png?auto=format,compress&w=78",
            name: "MartinLuther Lean"
        },
        {
            comments:"Being introduced to Dimers from my brother, I was not part of a community like this before. With great tools and features, it can make choosing the right props/bets really easy! Everyone is also very helpful and gives out advice & plays so that everyone can be successful, which is the best part of having a community like this— Dimers has been awesome!",
            image_url: "https://imgix.cosmicjs.com/f9bb1fe0-ece9-11ef-b3ed-03a7ab589b77-88d0b9d4-dceb-4539-9c56-8949f761a549.png?auto=format,compress&w=78",
            name: "Jessie DeGuzman"
        }
    ]
    productsSliderOptions = {
        align: 'start' as 'start',
        loop: true,
      }

    constructor(
        private ngZone: NgZone,
        private el: ElementRef,
      ) {}

    onEmblaChanged(type: EmblaEventType, emblaApi: EmblaCarouselType): void {
        if (type === 'init') {
            this.scrollSnaps.set(emblaApi.scrollSnapList());
          }

          if (type === 'select' || type === 'init' || type === 'reInit') {
            this.selectedIndex.set(emblaApi.selectedScrollSnap())
            this.prevBtnEnabled.set(emblaApi.canScrollPrev())
            this.nextBtnEnabled.set(emblaApi.canScrollNext())
            return
          }
    }

    prevSlide() {
        this.ngZone.runOutsideAngular(() => {
            this.emblaRef()?.scrollPrev();
        })
    }

    nextSlide() {
        this.ngZone.runOutsideAngular(() => {
            this.emblaRef()?.scrollNext();
        })
    }
    scrollTo(index:number) {
        this.ngZone.runOutsideAngular(()=> {
            this.emblaRef()?.scrollTo(index);
        })
    }

}