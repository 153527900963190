<div class="nav-section">
    <div class="mobile-logo">
        <a routerLink="/">
            <img alt="Dimers" loading="lazy" src="https://cdn.ciphersports.io/images/dimers-site/dimers-yellow.svg"
                height="68" width="300">
        </a>
    </div>
    <div class="right-side">
        <!-- <div class="bg-white p-2 flex items-center justify-center relative rounded-full h-8 w-8 cursor-pointer mr-2" (click)="openTagsModal()">
            <fa-icon [icon]="faBell" class="text-highlight-100 text-lg"></fa-icon>
            <span *ngIf="dotVisible" class="rounded-full bg-highlight-100 h-2 w-2 absolute top-0.5 right-0"></span>
        </div> -->
        <ng-container *ngIf="authHelper.userRetrievalDone && authHelper.user">
            <button class="user-dropdown rounded shadow px-2 py-2 bg-white flex" (click)="toggleAccountDropdown()">
                <img *ngIf="(proUser$ | async) === true" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6 mr-2" alt="dimers pro icon">
                <img *ngIf="(proUser$ | async) === false"  src="https://imgix.cosmicjs.com/e87d0d90-df63-11ee-a698-6f33e3e4a628-Dimers-Round-Logo-1.svg" class="h-6 w-6 mr-2" alt="dimers basic icon">
                <fa-icon [icon]="faChevronDown" aria-hidden="true"></fa-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="authHelper.userRetrievalDone && !authHelper.user">
            <div  (click)="authHelper.goToLoginWithState()">
                <button class="login-button"> Log In</button>
            </div>
            <div (click)="authHelper.goToLoginWithState(true,'/my-account')" class="ml-2">
                <button class="signup-button">Join For Free</button>
            </div>
        </ng-container>
</div>
</div>

<div class="fixed-footer shadow-md">
    <div class="footer-link" (click)="toggleNavSportsSidebar();">
        <img *ngIf="!navSportsSidebar" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/353c9e10-37fe-11ee-8ce3-27470be6bb93-Sports-OnDark.svg" height="1"
            width="1">
        <img *ngIf="navSportsSidebar" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/fb69a650-37fe-11ee-8ce3-27470be6bb93-Sports-Active.svg" height="1"
            width="1">
        Sports
    </div>
    <div class="footer-link" routerLink="/best-bets"
        (click)="sendTapTag('Picks');hideAllOpenMenuLinks();">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/356ada00-37fe-11ee-8ce3-27470be6bb93-QuickPicks-OnDark.svg" height="1"
            width="1">
        Best Bets
    </div>
    <div class="footer-link" routerLink="/parlay-picker"
        (click)="sendTapTag('Parlays');hideAllOpenMenuLinks();">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/4340c0a0-58eb-11ee-94c0-5f69598aa628-ParlayPicker-OnDark.svg?auto=format,compress&w=40" height="1"
            width="1">
        Parlays
    </div>
    <div class="footer-link" (click)="toggleNavProSidebar();">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" height="1"
            width="1">
        Pro
    </div>
    <div class="footer-link" (click)="toggleNavMoreSidebar();">
        <img *ngIf="!moreDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/353af060-37fe-11ee-8ce3-27470be6bb93-More-OnDark.svg" height="1" width="1">
        <img *ngIf="moreDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/fb6894e0-37fe-11ee-8ce3-27470be6bb93-More-Active.svg" height="1" width="1">
        More
    </div>
</div>
<div class="slide-in-menu about-menu" [class.onScreen]="accountDropdownOpen">
    <div class="back-button" (click)="hideAccountDropdown()">
        <fa-icon class="left-arrow" [icon]="faChevronDown"></fa-icon>
    </div>
    <div class="link-list">
        <ng-container *ngIf="authHelper.userRetrievalDone && authHelper.user; else loggedOut">
        <a class="link" (click)="hideAccountDropdown();selectTab('dashboard')" routerLink="/my-account">
                My Dimers
            </a>
            <a class="link" (click)="hideAccountDropdown();selectTab('details')"  routerLink="/my-account">
                Details
            </a>
            <a class="link" (click)="hideAccountDropdown();selectTab('subscription')" routerLink="/my-account">
                Subscription
            </a>
            <a class="link" (click)="hideAccountDropdown();selectTab('preferences')"  routerLink="/my-account">
                Preferences
            </a>
            <a *ngIf="(affiliate$ | async) === true" class="link" (click)="hideAccountDropdown()" href="https://dimers.promotekit.com/" target="_blank">
                Affiliate Dashboard
            </a>
            <span class="link" (click)="authHelper.logout({ returnTo: document.location.origin })">
                Log Out
            </span>
        </ng-container>
        <ng-template #loggedOut>
            <span class="link" (click)="authHelper.goToLoginWithState()">
                Log In
            </span>
            <span class="link" (click)="authHelper.goToLoginWithState(true,'/my-account')">
                Sign Up
            </span>
        </ng-template>
    </div>
</div>

<div class="nav-sports-sidebar overflow-y-auto" [ngClass]="this.navSportsSidebar ? 'show':'hide'">
    <ul class="list-none m-0">
        <li *ngFor="let sport of dropdownSports" class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                (click)="toggleNavSportsSidebar(); sendTapTag(sport.shortName)"
                [routerLink]="sport.navigablePages[0].path" [queryParams]="sport.navigablePages[0].queryParams">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <app-sport-icon *ngIf="navInteracted" [code]="sport.code" colour="white" class="w-5 h-5"></app-sport-icon>
                </div>
                <span>{{ sport.shortName }}</span>
            </a>
        </li>
        <li *ngFor="let sport of otherSports" class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                (click)="toggleNavSportsSidebar(); sendTapTag(sport.shortName)"
                [routerLink]="sport.navigablePages[0].path" [queryParams]="sport.navigablePages[0].queryParams">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <app-sport-icon *ngIf="navInteracted" [code]="sport.code" colour="white" class="w-5 h-5"></app-sport-icon>
                </div>
                <span>{{ sport.shortName }}</span>
            </a>
        </li>

    </ul>
</div>

<div class="nav-more-sidebar overflow-y-auto" [ngClass]="this.navMoreSidebar ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full" routerLink="/live-now"
                (click)="toggleNavMoreSidebar(); sendTapTag('Live Now')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/544b7150-58fb-11ee-94c0-5f69598aa628-LiveNow-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Live Scores
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full" routerLink="/news"
                (click)="toggleNavMoreSidebar(); sendTapTag('News')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/f8f63920-220d-11ef-9fc0-dbae8bde9933-News-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    News
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/sports-betting-101" (click)="toggleNavMoreSidebar(); sendTapTag('Betting Explained')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/432c2730-58eb-11ee-94c0-5f69598aa628-BettingExplained-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Betting Explained
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/business" (click)="toggleNavMoreSidebar(); sendTapTag('Dimers For Business')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/839aa250-9320-11ee-8a8d-abf95e574482-business-icon.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Dimers For Business
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/responsible-gambling" (click)="toggleNavMoreSidebar(); sendTapTag('Gamble Responsibly')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/4325e5a0-58eb-11ee-94c0-5f69598aa628-GambleResponsibly-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Gamble Responsibly
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <div class="flex items-center text-sm text-white py-2 px-4 h-full"
                (click)="toggleNavMoreSidebar(); sendTapTag('Newsletter'); fireNewsletterPopUp();">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/432b15c0-58eb-11ee-94c0-5f69598aa628-Newsletter-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Join Newsletter
                </div>
            </div>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/betting" (click)="toggleNavMoreSidebar();sendTapTag('Betting Sites')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Betting Sites
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/social-sportsbooks" (click)="toggleNavMoreSidebar(); sendTapTag('SocialSportsbooks')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Social Sportsbooks
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/dfs" (click)="toggleNavMoreSidebar(); sendTapTag('DFS')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    DFS Sites
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/online-casinos" (click)="toggleNavMoreSidebar(); sendTapTag('OnlineCasinos')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Online Casinos
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/sweepstakes-casinos" (click)="toggleNavMoreSidebar(); sendTapTag('Sweepstakes')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Sweepstakes Casinos
                </div>
            </a>
        </li>
    </ul>
</div>

<div class="pro-betting-sidebar overflow-y-auto" [ngClass]="navProSidebar ? 'show': 'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/subscription" (click)="toggleNavProSidebar()">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Pricing
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/subscription/how-to-use-dimers-pro" (click)="toggleNavProSidebar();">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    How To Use 
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/subscription/faq" (click)="toggleNavProSidebar();">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    FAQ 
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
               href="https://dimers.supahub.com/" target="_blank" (click)="toggleNavMoreSidebar()">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Suggest a Feature
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
            routerLink="/subscription/affiliate" (click)="toggleNavMoreSidebar()">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Become an Affiliate
                </div>
            </a>
        </li>

    </ul>
</div>
