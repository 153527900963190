import { Injectable, /*Inject, PLATFORM_ID*/ } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, shareReplay, timeout } from "rxjs/operators";
import { BlogArticle } from './blog-article.model';
// import { PromoArticle } from './promo-article.model';
import { BlogAuthor } from './blog-author.model';
// import { BookOffer } from './book-offer.model';
import { SPORTS } from './sports';
import { LocalisationService } from './localisation.service';
import { UserLocationData } from "./user-location-data.model";
import { PageMetaObject } from "./page-meta-object.model";
import { AdhesiveBanner } from "./adhesive-banner.model";
import { environment } from 'src/environments/environment';
// import { TransferState, makeStateKey } from '@angular/platform-browser';
import { TitleCasePipe, /*isPlatformBrowser*/ } from '@angular/common';
// import { SportsBetting101Category } from "./sports-betting-101-category.model";
// import { SportsBetting101Article } from "./sports-betting-101-article.model";
import { compareDesc, format, formatDistance, parseJSON } from 'date-fns';
import { GeneralService } from './general.service';
import { Match } from './match.model';
import { BetService } from './bet.service';
import { MatchBet } from './match-bet.model';
import { MatchService } from './match.service';
import { BOOKMAKERS } from './bookmakers';
import { SportDataService } from './sport-data.service';
import { Router } from '@angular/router';
import { IsLivePipe } from './match-pipes/is-live.pipe';
import { IsOverPipe } from './match-pipes/is-over.pipe';

@Injectable({
	providedIn: 'root'
})
export class BlogService {
    
	cosmmicApi = environment.cosmicApiDomain;
	cosmmicApiUncached = environment.cosmicUncachedDomain;
	cosmicBucketSlug = 'ciphercms-production';
	cosmicReadKey ="kIszTMwQkliPYn5yOQCHtMAZoW76qEs5Kp8Kr2jxoXf2oIkMch";
	backupStateID = "67cfaef40ed056126d2b2741";

	adhesiveBannersVisible = true;
    proPopUp = true;
    feedbackFormVisible = true;
    // browserMode:boolean = false;
	
    private proModalDataSubject = new BehaviorSubject<{modalVisible: boolean, betIntent:boolean}>({modalVisible:false, betIntent:false});
    proModalData$ = this.proModalDataSubject.asObservable();

    private onBoardingPopupDataSubject = new BehaviorSubject<boolean>(false);
    onBoardingPopupData$ = this.onBoardingPopupDataSubject.asObservable();

    private selectedTabSubject: BehaviorSubject<string> = new BehaviorSubject<string>('dashboard');
    selectedTabChanged = this.selectedTabSubject.asObservable();
	
    private tagsHelpInfoPopupDataSubject = new BehaviorSubject<boolean>(false);
    tagsHelpInfoPopupData$ = this.tagsHelpInfoPopupDataSubject.asObservable();

    private tagsNotificationPopupDataSubject = new BehaviorSubject<boolean>(false);
    tagsNotificationPopupData$ = this.tagsNotificationPopupDataSubject.asObservable();

    setTagsNotificationModalData(data:boolean) {
        this.tagsNotificationPopupDataSubject.next(data);
    }
    setTagsHelpInfoModalData(data:boolean) {
        this.tagsHelpInfoPopupDataSubject.next(data);
    }

    setSelectedMyaccountTab(tab: string) {
        this.selectedTabSubject.next(tab);
    }

	setProModalData(modalVisible: boolean, betIntent?: boolean) {
        this.proModalDataSubject.next({
            modalVisible: modalVisible,
            betIntent: !!betIntent
        });
    }

    setonBoardingPopupData(data: boolean) {
        this.onBoardingPopupDataSubject.next(data);
    }

    sharedData:any;

    
	userLocationData$: Observable<UserLocationData> = this.http.get<any>(`${environment.dimersGeoDomain}/v1/get_geolocation`)
		.pipe(
			// timeout({first: 5000, meta: {methodName: "userLocationData$"}}),
			map((response: any) => {
				if (response) {
					return (response as UserLocationData);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			shareReplay(1),
			catchError(this.handleError<UserLocationData>())
		);

     private jumpToSectionSource = new Subject<string>();
    jumpToSection$ = this.jumpToSectionSource.asObservable();

	constructor(
		private http: HttpClient,
        // private transferState: TransferState,
		private localisationService: LocalisationService,
        // @Inject(PLATFORM_ID) platformId: string,
		private generalService: GeneralService,
		private betService: BetService,
		private matchService: MatchService,
		private sportDataService: SportDataService,
		private titleCasePipe: TitleCasePipe,
        private router: Router,
        private isLivePipe: IsLivePipe,
		private isOverPipe: IsOverPipe,
	) { 
        // this.browserMode = isPlatformBrowser(platformId);
    }


    jumpToSection(section: string) {
        this.jumpToSectionSource.next(section);
    }

    setRegistrationData(data: any) {
        this.sharedData = data;
    }
  
    getRegistrationData(){
        return this.sharedData;
    }

    convertCosmicAuthors(authors: Array<Record<string, any>>,author: Record<string, any>):Array<BlogAuthor> {
        if(authors?.length > 0) {
            return authors.map(author => this.convertCosmicAuthor(author));
        } else {
            return [this.convertCosmicAuthor(author)];
        }
    }
    
	convertCosmicAuthor(author: Record<string, any>): BlogAuthor {
		return {
			id: author.id,
			last_name: (author.metadata.author_first_name && author.title.includes(author.metadata.author_first_name)) ? author.title.replace(author.metadata.author_first_name, "").trim() : author.title,
			slug: author.slug,
			first_name: (author.metadata.author_first_name && author.title.includes(author.metadata.author_first_name)) ? author.metadata.author_first_name : "",
			social_username: author.metadata.twitter_username,
			description: author.content,
			short_bio: author.metadata.short_bio || author.content,
			social_summary_title: author.title,
			social_summary_description: author.content,
			thumbnail: {
				dynamic_url: author.metadata.author_thumbnail.imgix_url,
			},
			same_as: author.metadata.same_as,
			job_title: author.metadata.title,
			years_of_experience: author.metadata.year_of_experience,
			social_thumbnail: {
				dynamic_url: null
			},
			linkedin_link: author.metadata.linkedin_link
		};
	}

	disableAdhesiveBanners(): void {
		this.adhesiveBannersVisible = false;
	}

    disableFeedbackForm(): void {
        this.feedbackFormVisible = false;
    }

    disablePopUpOffer(): void {
		this.proPopUp = false;
	}

	getArticle(slug: string, revisionID?: string): Observable<BlogArticle> {
		console.log('getArticle');
		let rno = Math.random()
		console.time('getArticle' + rno)
		const query = {
			"type": "dimers-articles",
			"slug": slug,
		};
		if (revisionID) {
			return this.http.get<any>(`${this.cosmmicApiUncached}${this.cosmicBucketSlug}/objects?`
				+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&limit=1&props=id`
				+ `&read_key=${this.cosmicReadKey}&status=any&depth=2`)
			.pipe(
				mergeMap((response1: any) => {
					console.log("gotten article:")
					console.timeLog('getArticle' + rno)
					if (response1.objects && response1.objects.length > 0) {
						const articleID = response1.objects[0].id;
						return this.http.get<any>(`${this.cosmmicApiUncached}${this.cosmicBucketSlug}/objects/${articleID}/revisions/${revisionID}`
							+ `?read_key=${this.cosmicReadKey}&depth=1`)
					} else if (response1.errors) {
						throw new Error(response1.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				map((response2: any) => {
					if (response2.revision) {
						console.log("gotten revision:");
						console.timeLog('getArticle' + rno)
						const o = response2.revision;
						return {
							// TODO deal with autocon case
							article_category: "dimers_content" as "dimers_content",
							// author: o.metadata.author.title,
							authors: this.convertCosmicAuthors(o.metadata.authors, o.metadata.author),
                            reviewer: o.metadata.reviewer ? this.convertCosmicAuthor(o.metadata.reviewer) : undefined,
							content_description: o.content,
							created_at: o.created_at,
							featured_article: null,
							id: o.id,
							published_date: null,
							published_date_readable: "Unpublished",
							unpublished_at: o.unpublish_at,
							short_title: o.short_title || o.title,
							slug: o.slug,
							socialThumbnail: {
								url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
							},
							social_summary_description: o.metadata.preview_text,
							social_summary_title: o.social_summary_title || o.title,
							sport_betting_category: null,
							summarized_description: o.metadata.preview_text,
							subtitle: o.metadata.subtitle,
							tags: o.metadata.categories.map(c => ({
								name: c,
								slug: c,
							})),
							thumbnail: {
								dynamic_url: o.metadata.hero_image.imgix_url,
								url: o.metadata.hero_image.url,
								custom_properties: {
									alt_text: o.metadata.hero_image_alt || undefined,
								}
							},
							thumbnail_caption: o.metadata.hero_image_caption,
							title: o.title,
							faqs: [],
							show_faq: false,
						};
					} else if (response2.errors) {
						throw new Error(response2.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<BlogArticle>())
			)
		} else {
			let o: any;
			return this.http.get<any>(`${this.cosmmicApiUncached}${this.cosmicBucketSlug}/objects?`
				+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
				+ `&limit=1&read_key=${this.cosmicReadKey}&depth=1`)
			.pipe(
				mergeMap((mainResponse: any) => {
					console.log("gotten article id:")
					console.timeLog('getArticle' + rno)
					if (mainResponse.objects && mainResponse.objects.length > 0) {
						o = mainResponse.objects[0];
						const articleID = o.id;
						return this.http.get<any>(`${this.cosmmicApiUncached}${this.cosmicBucketSlug}/objects/${articleID}/revisions?limit=1&sort=created_at&query=%7B"status"%3A"published"%7D&read_key=${this.cosmicReadKey}`)
					} else if (mainResponse.errors) {
						throw new Error(mainResponse.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				map((revisionsResponse: any) => {
					console.log("gotten article revision:")
					console.timeLog('getArticle' + rno)
					if (revisionsResponse.revisions && revisionsResponse.revisions.length > 0) {
						console.log("revisions found");
						console.log("originally_published = " + o.metadata.originally_published)
						const earliestPublished = o.metadata.originally_published || revisionsResponse.revisions[0].created_at;
						{
							return {
								// TODO deal with autocon case
								article_category: "dimers_content" as "dimers_content",
								// author: o.metadata.author.title,
                                authors: this.convertCosmicAuthors(o.metadata.authors, o.metadata.author),
                                reviewer:  o.metadata.reviewer ? this.convertCosmicAuthor(o.metadata.reviewer) : undefined,
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: earliestPublished,
								published_date_readable: formatDistance(parseJSON(earliestPublished), new Date()) + " ago",
								last_updated: o.published_at,
								unpublished_at: o.unpublish_at,
								short_title: o.short_title || o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.social_summary_title || o.title,
								sport_betting_category: null,
								summarized_description: o.metadata.preview_text,
								subtitle: o.metadata.subtitle,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
									custom_properties: {
										alt_text: o.metadata.hero_image_alt || undefined,
									}
								},
								thumbnail_caption: o.metadata.hero_image_caption,
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						}
					} else if (revisionsResponse.status) {
						throw new Error(revisionsResponse)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError((e) => {
					return this.handleError<BlogArticle>()(e)
				})
			)
		}
	}

	// getPromoArticle(slug: string, revisionID?: string): Observable<PromoArticle> {
	// 	// console.log('getArticle');
	// 	let rno = Math.random()
	// 	// console.time('getArticle' + rno)
	// 	const query = {
	// 		"type": "dimers-best-promos-today-articles",
	// 		"slug": slug,
	// 	};
	// 	if (revisionID) {
	// 		return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects?`
	// 			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&limit=1&props=id`
	// 			+ `&read_key=${this.cosmicReadKey}&status=any`)
	// 		.pipe(
	// 			// timeout({first: 5000, meta: {methodName: "getPromoArticle (revision)"}}),
	// 			mergeMap((response1: any) => {
	// 				// console.log("gotten article:")
	// 				// console.timeLog('getArticle' + rno)
	// 				if (response1.objects && response1.objects.length > 0) {
	// 					const articleID = response1.objects[0].id;
	// 					return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects/${articleID}/revisions/${revisionID}`
	// 						+ `?read_key=${this.cosmicReadKey}`)
	// 				} else if (response1.errors) {
	// 					throw new Error(response1.errors[0].message)
	// 				} else {
	// 					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 				}
	// 			}),
	// 			map((response2: any) => {
	// 				if (response2.revision) {
	// 					// console.log("gotten revision:");
	// 					// console.timeLog('getArticle' + rno)
	// 					const o = response2.revision;
	// 					return {
	// 						// TODO deal with autocon case
	// 						article_category: "dimers_content" as "dimers_content",
	// 						// author: o.metadata.author.title,
	// 						authors: [{
	// 							id: o.metadata.author.id,
	// 							last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
	// 							slug: o.metadata.author.metadata.appetiser_slug,
	// 							first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
	// 							social_username: o.metadata.author.metadata.twitter_username,
	// 							description: o.metadata.author.content,
	// 							short_bio: o.metadata.author.metadata.short_bio || o.metadata.author.content,
	// 							social_summary_title: undefined,
	// 							social_summary_description: undefined,
	// 							thumbnail: {
	// 								dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
	// 							},
	// 							job_title: o.metadata.author.metadata.title,
	// 							years_of_experience: o.metadata.author.metadata.years_of_experience,
	// 							linkedin_link: o.metadata.author.metadata.linkedin_link,
	// 							same_as: o.metadata.author.metadata.same_as || [],
	// 						}],
	// 						content_description: o.content,
	// 						created_at: o.created_at,
	// 						featured_article: null,
	// 						id: o.id,
	// 						published_date: null,
	// 						published_date_readable: "Unpublished",
	// 						unpublished_at: o.unpublish_at,
	// 						short_title: o.short_title || o.title,
	// 						slug: o.slug,
	// 						socialThumbnail: {
	// 							url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
	// 						},
	// 						social_summary_description: o.metadata.preview_text,
	// 						social_summary_title: o.social_summary_title || o.title,
	// 						sport_betting_category: null,
	// 						summarized_description: o.metadata.preview_text,
	// 						subtitle: o.metadata.subtitle,
	// 						tags: o.metadata.categories.map(c => ({
	// 							name: c,
	// 							slug: c,
	// 						})),
	// 						thumbnail: {
	// 							dynamic_url: o.metadata.hero_image.imgix_url,
	// 							url: o.metadata.hero_image.url
	// 						},
	// 						thumbnail_caption: o.metadata.hero_image_caption,
	// 						title: o.title,
	// 						faqs: [],
	// 						show_faq: false,
	// 					} as PromoArticle;
	// 				} else if (response2.errors) {
	// 					throw new Error(response2.errors[0].message)
	// 				} else {
	// 					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 				}
	// 			}),
	// 			catchError(this.handleError<PromoArticle>())
	// 		)
	// 	} else {
	// 		let o: any;
	// 		return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects?`
	// 			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
	// 			+ `&limit=1&read_key=${this.cosmicReadKey}`)
	// 		.pipe(
	// 			// timeout({first: 5000, meta: {methodName: "getPromoArticle (no-revision)"}}),
	// 			mergeMap((mainResponse: any) => {
	// 				// console.log("gotten article id:")
	// 				// console.timeLog('getArticle' + rno)
	// 				if (mainResponse.objects && mainResponse.objects.length > 0) {
	// 					o = mainResponse.objects[0];
	// 					const articleID = o.id;
	// 					return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects/${articleID}/revisions?limit=1&sort=created_at&query=%7B"status"%3A"published"%7D&read_key=${this.cosmicReadKey}`)
	// 				} else if (mainResponse.status) {
	// 					throw new Error(mainResponse)
	// 				} else {
	// 					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 				}
	// 			}),
	// 			map((revisionsResponse: any) => {
	// 				// console.log("gotten article revision:")
	// 				// console.timeLog('getArticle' + rno)
	// 				if (revisionsResponse.revisions && revisionsResponse.revisions.length > 0) {
	// 					const earliestPublished = revisionsResponse.revisions[0].created_at;
	// 					{
	// 						return {
	// 							// TODO deal with autocon case
	// 							article_category: "dimers_content" as "dimers_content",
	// 							// author: o.metadata.author.title,
	// 							authors: [{
	// 								id: o.metadata.author.id,
	// 								last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
	// 								slug: o.metadata.author.metadata.appetiser_slug,
	// 								first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
	// 								social_username: o.metadata.author.metadata.twitter_username,
	// 								description: o.metadata.author.content,
	// 								short_bio: o.metadata.author.metadata.short_bio || o.metadata.author.content,
	// 								social_summary_title: undefined,
	// 								social_summary_description: undefined,
	// 								thumbnail: {
	// 									dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
	// 								},
	// 								job_title: o.metadata.author.metadata.title,
	// 								years_of_experience: o.metadata.author.metadata.years_of_experience,
	// 								linkedin_link: o.metadata.author.metadata.linkedin_link,
	// 								same_as: o.metadata.author.metadata.same_as || [],
	// 							}],
	// 							content_description: o.content,
	// 							created_at: o.created_at,
	// 							featured_article: null,
	// 							id: o.id,
	// 							published_date: earliestPublished,
	// 							published_date_readable: formatDistance(parseJSON(earliestPublished), new Date()) + " ago",
	// 							last_updated: o.published_at,
	// 							unpublished_at: o.unpublish_at,
	// 							short_title: o.short_title || o.title,
	// 							slug: o.slug,
	// 							socialThumbnail: {
	// 								url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
	// 							},
	// 							social_summary_description: o.metadata.preview_text,
	// 							social_summary_title: o.social_summary_title || o.title,
	// 							sport_betting_category: null,
	// 							summarized_description: o.metadata.preview_text,
	// 							subtitle: o.metadata.subtitle,
	// 							tags: o.metadata.categories.map(c => ({
	// 								name: c,
	// 								slug: c,
	// 							})),
	// 							thumbnail: {
	// 								dynamic_url: o.metadata.hero_image.imgix_url,
	// 								url: o.metadata.hero_image.url
	// 							},
	// 							thumbnail_caption: o.metadata.hero_image_caption,
	// 							title: o.title,
	// 							faqs: [],
	// 							show_faq: false,
	// 						} as PromoArticle;
	// 					}
	// 				} else if (revisionsResponse.status) {
	// 					throw new Error(revisionsResponse)
	// 				} else {
	// 					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 				}
	// 			}),
	// 			catchError(this.handleError<PromoArticle>())
	// 		)
	// 	}
	// }

	// TODO
	// getLatestFeaturedGame(): Observable<String> {
	// 	return of("");
	// }

	getLatestArticleSummaries(count: number): Observable<Array<BlogArticle>> {
		console.log('getLatestArticleSummaries');
		
		let rno = Math.random()
		console.time('getLatestArticleSummaries ' + rno)
		const query = {
			"type": "dimers-articles",
			// "metadata.locale_exclusivity": {"$in": ["All Locales", this.localisationService.locale + " Exclusive", ""]}
		}
		return this.http.get<any>(`${this.cosmmicApiUncached}${this.cosmicBucketSlug}/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=${count}&read_key=${this.cosmicReadKey}&sort=-published_at&depth=2`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));
					console.log("latest article summaries retrieved:")
					console.timeLog('getLatestArticleSummaries '+rno)

					const articles = response.objects;

					console.log("latest article summaries sorted:")
					console.timeLog('getLatestArticleSummaries '+rno)
					const transformedArticles = articles
						.map(o => {
							let combinedDate;
							if (o.metadata.originally_published) {
								combinedDate = o.metadata.originally_published
							} else {
								combinedDate = o.published_at;
							}
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								// author: o.metadata.author.title,
								authors: [this.convertCosmicAuthor(o.metadata.author)],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: combinedDate,
								published_date_readable: formatDistance(parseJSON(combinedDate), new Date()) + " ago",
								last_updated: o.published_at,
								unpublished_at: o.unpublish_at,
								short_title: o.short_title || o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.social_summary_title || o.title,
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								subtitle: o.metadata.subtitle,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
									custom_properties: {
										alt_text: o.metadata.hero_image_alt || undefined,
									}
								},
								thumbnail_caption: o.metadata.hero_image_caption,
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						});
					
					console.log("latest article summaries transformed:")
					console.timeLog('getLatestArticleSummaries '+rno);
					return transformedArticles;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	// getBuilderPage(pageCode: string): Observable<Record<string, any>> {
	// 	return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/page-builders?filter[path_url]=/${pageCode}`)
	// 	.pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getBuilderPage"}}),
	// 		map((response: any) => {
	// 			if (response.data) {
	// 				return response.data[0]
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Record<string, any>>())
	// 	)
	// }

	// builderPageExists(pageCode: string): Observable<boolean> {
	// 	return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/page-builders?filter[path_url]=/${pageCode}`)
	// 	.pipe(
	// 		// timeout({first: 5000, meta: {methodName: "builderPageExists"}}),
	// 		map((response: any) => {
	// 			return response.data && response.data.length > 0;
	// 		}),
	// 		catchError(() => {
	// 			return of(false)
	// 		})
	// 	)
	// }

    landingPageExists(pageCode: string): Observable<boolean>{
        const slug = "dimers-" + pageCode;
        const query = {
            "type": "dimers-pages",
            "slug": slug
        };

        const options = new HttpParams()
        .set("read_key", this.cosmicReadKey)
        .set("query", JSON.stringify(query) )
        .set("props", "slug,title,metadata")
        .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
            map(data => {
                return data.objects?.length > 0;
            }),
			catchError(() => {
				return of(false)
			})
        );
    }

	// TODO
	// getLatestArticleSummariesByTag(tag: string, count: number): Observable<Array<BlogArticle>> {
	// 	return of([]);
	// }

	getLatestArticleSummariesByCategory(category: string, count: number, exceptID?: number): Observable<Array<BlogArticle>> {
		console.log('getLatestArticleSummariesByCategory');

        const query = {
			"type": "dimers-articles",
			"metadata.categories": category.toUpperCase(),
			"id": exceptID ? {
				"$ne": exceptID
			} : undefined
		}

		return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
			+ `&limit=${count}&read_key=${this.cosmicReadKey}&sort=-published_at&depth=2`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));
					return response.objects
						.map(o => {
								
							let combinedDate;
							if (o.metadata.originally_published) {
								combinedDate = o.metadata.originally_published
							} else {
								combinedDate = o.published_at;
							}
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								// author: o.metadata.author.title,
								authors: [this.convertCosmicAuthor(o.metadata.author)],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: combinedDate,
								published_date_readable: formatDistance(parseJSON(combinedDate), new Date()) + " ago",
								last_updated: o.published_at,
								unpublished_at: o.unpublish_at,
								short_title: o.short_title || o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.social_summary_title || o.title,
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								subtitle: o.metadata.subtitle,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
									custom_properties: {
										alt_text: o.metadata.hero_image_alt || undefined,
									}
								},
								thumbnail_caption: o.metadata.hero_image_caption,
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						});
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	getFeaturedArticleSummaries(count: number, category?: string): Observable<Array<BlogArticle>> {
		console.log('getFeaturedArticleSummaries');
		let rno = Math.random()
		console.time('getFeaturedArticleSummaries ' + rno)
		const query = {
			"type": "dimers-featured-article-list",
			"slug": "dimers-featured-article-list",
		}
		return this.http.get<any>(`${this.cosmmicApiUncached}${this.cosmicBucketSlug}/objects?`
			+ `query=${JSON.stringify(query).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&props=metadata`
			+ `&limit=1&read_key=${this.cosmicReadKey}&depth=3`)
		
		.pipe(
			map((response: any) => {
				// console.log(response)

				// let span = tracer.startSpan("expensive-query");

				if (response.objects?.[0]?.metadata.featured_articles?.length > 0) {
					// this.honeycomb.sendMessage({
					// 	message: `finished retrieval from blog service`
					// });
			
					console.log("featured article summaries retrieved:")
					console.timeLog('getFeaturedArticleSummaries '+rno)

					const articles = response.objects[0].metadata.featured_articles.filter(a => a.status === "published").slice(0,count);
					
					console.log("featured article summaries sorted:")
					console.timeLog('getFeaturedArticleSummaries '+rno)
					const transformedArticles = articles
						.map(o => {
								
							let combinedDate;
							if (o.metadata.originally_published) {
								combinedDate = o.metadata.originally_published
							} else {
								combinedDate = o.published_at;
							}
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								// author: o.metadata.author.title,
								authors: [this.convertCosmicAuthor(o.metadata.author)],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: combinedDate,
								published_date_readable: formatDistance(parseJSON(combinedDate), new Date()) + " ago",
								last_updated: o.published_at,
								unpublished_at: o.unpublish_at,
								short_title: o.short_title || o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.social_summary_title || o.title,
								sport_betting_category: [],
								summarized_description: o.metadata.preview_text,
								subtitle: o.metadata.subtitle,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
									custom_properties: {
										alt_text: o.metadata.hero_image_alt || undefined,
									}
								},
								thumbnail_caption: o.metadata.hero_image_caption,
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						}) as Array<BlogArticle>;
					
					console.log("featured article summaries converted:")
					console.timeLog('getFeaturedArticleSummaries '+rno);
					return transformedArticles;
					// .sort((a,b) => a.featured_article?.order_index - b.featured_article?.order_index);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
            catchError(error => {
                if (error.status === 404) {
                    return of([]); // Return empty data for 404 errors
                }
                  throw error;
            })
		)
	}

	authors$: Observable<Array<BlogAuthor>> = this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects?`
		+ `query=${JSON.stringify({
			"type": "dimers-best-book-authors",
		}).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}`
		+ `&read_key=${this.cosmicReadKey}&props=id,title,slug,content,metadata&depth=2`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					return response.objects.map(a => this.convertCosmicAuthor(a));
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogAuthor>>())
		)
	

	getAuthor(slug: string): Observable<BlogAuthor> {
		return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects?query=%7B%22type%22%3A%22dimers-best-book-authors%22%2C%22slug%22%3A%22${slug}%22%7D`
			+ `&read_key=${this.cosmicReadKey}&props=id,slug,title,content,metadata&depth=2`)
		.pipe(
			map((response: any) => {
				if (response.objects?.length > 0) {
					let author = response.objects[0];
					return this.convertCosmicAuthor(author);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<BlogAuthor>())
		)
	}

	getArticleSummaryByMatchID(matchID: string): Observable<Array<BlogArticle>> {
		const queryObject = {
			"type": "dimers-articles",
			"metadata.matchid": matchID,
		};

		return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects?`
			+ `limit=1&query=${JSON.stringify(queryObject).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&props=title,slug,published_at,metadata`
			+ `&read_key=${this.cosmicReadKey}&sort=-published_at&depth=2`)
		.pipe(
			map((response: any) => {
				if (response && response.objects && response.total) {
                    
					return response.objects
                        .map(o => {
							
							let combinedDate;
							if (o.metadata.originally_published) {
								combinedDate = o.metadata.originally_published
							} else {
								combinedDate = o.published_at;
							}
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								// author: o.metadata.author.title,
								authors: [this.convertCosmicAuthor(o.metadata.author)],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: combinedDate,
								published_date_readable: formatDistance(parseJSON(combinedDate), new Date()) + " ago",
								last_updated: o.published_at,
								unpublished_at: o.unpublish_at,
								short_title: o.short_title || o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.social_summary_title || o.title,
								sport_betting_category: null,
								summarized_description: o.metadata.preview_text,
								subtitle: o.metadata.subtitle,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
									custom_properties: {
										alt_text: o.metadata.hero_image_alt || undefined,
									}
								},
								thumbnail_caption: o.metadata.hero_image_caption,
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						}) as Array<BlogArticle>;
				} else if (response && (response.objects === null || !response.total)) {
					return [];
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
			
	}

	getArticleSummariesPage(authorID: string, category: string, query: string, skip: number = 0, count: number): Observable<{articles: Array<BlogArticle>, totalCount: number}> {
		const queryObject = {
			"type": "dimers-articles",
			"metadata.author": authorID ? authorID : undefined,
			"metadata.categories": category ? category.toUpperCase() : undefined,
			// "metadata.locale_exclusivity": {"$in": ["All Locales", this.localisationService.locale + " Exclusive", ""]},
			"$or": query ? [
				{"title": {"$regex": query, "$options": "i"}},
				{"content": {"$regex": query, "$options": "i"}}
			] : undefined,
		}
		return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects?`
			+ `limit=${count}&query=${JSON.stringify(queryObject).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&props=title,slug,published_at,metadata`
			+ `&read_key=${this.cosmicReadKey}&sort=-published_at&skip=${skip}&depth=2`)
		.pipe(
			map((response: any) => {
				if (response && response.objects && response.total) {
                    
					return {articles: (response.objects
						.map(o => {
							
							let combinedDate;
							if (o.metadata.originally_published) {
								combinedDate = o.metadata.originally_published
							} else {
								combinedDate = o.published_at;
							}
							return {
								// TODO deal with autocon case
								article_category: "dimers_content",
								// author: o.metadata.author.title,
								authors: [this.convertCosmicAuthor(o.metadata.author)],
								content_description: o.content,
								created_at: o.created_at,
								featured_article: null,
								id: o.id,
								published_date: combinedDate,
								published_date_readable: formatDistance(parseJSON(combinedDate), new Date()) + " ago",
								last_updated: o.published_at,
								unpublished_at: o.unpublish_at,
								short_title: o.short_title || o.title,
								slug: o.slug,
								socialThumbnail: {
									url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
								},
								social_summary_description: o.metadata.preview_text,
								social_summary_title: o.social_summary_title || o.title,
								sport_betting_category: null,
								summarized_description: o.metadata.preview_text,
								subtitle: o.metadata.subtitle,
								tags: o.metadata.categories.map(c => ({
									name: c,
									slug: c,
								})),
								thumbnail: {
									dynamic_url: o.metadata.hero_image.imgix_url,
									url: o.metadata.hero_image.url,
									custom_properties: {
										alt_text: o.metadata.hero_image_alt || undefined,
									}
								},
								thumbnail_caption: o.metadata.hero_image_caption,
								title: o.title,
								faqs: [],
								show_faq: false,
							}
						}) as Array<BlogArticle>), totalCount: (response.total as number)};
				} else if (response && (response.objects === null || !response.total)) {
					return {articles: [], totalCount: 0};
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<{articles: Array<BlogArticle>, totalCount: number}>())
		)
	}

	// getPromoArticleSummariesPage(authorID: number, category: string, query: string, skip: number = 0, count: number): Observable<{articles: Array<PromoArticle>, totalCount: number}> {
	// 	const queryObject = {
	// 		"type": "dimers-best-promos-today-articles",
	// 		"metadata.author": authorID ? authorID : undefined,
	// 		"metadata.categories": category ? category.toUpperCase() : undefined,
	// 		"metadata.locale_exclusivity": {"$in": ["All Locales", this.localisationService.locale + " Exclusive", ""]},
	// 		"$or": query ? [
	// 			{"title": {"$regex": query, "$options": "i"}},
	// 			{"content": {"$regex": query, "$options": "i"}}
	// 		] : undefined,
	// 	}
	// 	return this.http.get<any>(`${this.cosmmicApi}${this.cosmicBucketSlug}/objects?`
	// 		+ `limit=${count}&query=${JSON.stringify(queryObject).replace(/\{/g, "%7B").replace(/\}/g, "%7D")}&props=title,slug,published_at,metadata`
	// 		+ `&read_key=${this.cosmicReadKey}&sort=-modified_at&skip=${skip}`)
	// 	.pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getPromoArticleSummariesPage"}}),
	// 		map((response: any) => {
	// 			if (response && response.objects && response.total) {
                    
	// 				return {articles: (response.objects
	// 					.sort((a,b) => compareDesc(parseJSON(a.published_at), parseJSON(b.published_at)))
    //                     .map(o => {
							
	// 					// let combinedDate;
	// 					// let postDate = parseISO(o.metadata.originally_published);
	// 					let timeDate = parseJSON(o.published_at);
	// 					// combinedDate = new Date(postDate.getFullYear(), postDate.getMonth(), postDate.getDate(),
	// 					// 	timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds());
	// 					return {
	// 						// TODO deal with autocon case
	// 						article_category: "dimers_content",
	// 						author: o.metadata.author.title,
	// 						authors: [{
	// 							id: o.metadata.author.id,
	// 							last_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.title.replace(o.metadata.author.metadata.author_first_name, "").trim() : o.metadata.author.title,
	// 							slug: o.metadata.author.metadata.appetiser_slug,
	// 							first_name: (o.metadata.author.metadata.author_first_name && o.metadata.author.title.includes(o.metadata.author.metadata.author_first_name)) ? o.metadata.author.metadata.author_first_name : "",
	// 							social_username: o.metadata.author.metadata.twitter_username,
	// 							description: o.metadata.author.content,
	// 							short_bio: o.metadata.author.metadata.short_bio ||o.metadata.author.content,
	// 							social_summary_title: undefined,
	// 							social_summary_description: undefined,
	// 							thumbnail: {
	// 								dynamic_url: o.metadata.author.metadata.author_thumbnail.imgix_url,
	// 							},
	// 							job_title: o.metadata.author.metadata.title,
	// 							years_of_experience: o.metadata.author.metadata.years_of_experience,
	// 							linkedin_link: o.metadata.author.metadata.linkedin_link,
	// 							same_as: o.metadata.author.metadata.same_as || [],
	// 						}],
	// 						content_description: o.content,
	// 						created_at: o.created_at,
	// 						featured_article: null,
	// 						id: o.id,
	// 						published_date: timeDate.toJSON(),
	// 						published_date_readable: formatDistance(timeDate, new Date()) + " ago",
	// 						unpublished_at: o.unpublish_at,
	// 						short_title: o.short_title || o.title,
	// 						slug: o.slug,
	// 						socialThumbnail: {
	// 							url: o.metadata.social_thumbnail?.url || o.metadata.hero_image.url,
	// 						},
	// 						social_summary_description: o.metadata.preview_text,
	// 						social_summary_title: o.social_summary_title || o.title,
	// 						sport_betting_category: null,
	// 						summarized_description: o.metadata.preview_text,
	// 						subtitle: o.metadata.subtitle,
	// 						tags: o.metadata.categories.map(c => ({
	// 							name: c,
	// 							slug: c,
	// 						})),
	// 						thumbnail: {
	// 							dynamic_url: o.metadata.hero_image.imgix_url,
	// 							url: o.metadata.hero_image.url,
	// 						},
	// 						title: o.title,
	// 						faqs: [],
	// 						show_faq: false,
	// 					}
	// 				}) as Array<PromoArticle>), totalCount: (response.total as number)};
	// 			} else if (response && (response.objects === null || !response.total)) {
	// 				return {articles: [], totalCount: 0};
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<{articles: Array<PromoArticle>, totalCount: number}>())
	// 	)
	// }

	getAuthorSummariesPage(authorSlug: string, skip: number, count: number) {
		return of([]);
	}

	getAdhesiveBanner(stateId: string): Observable<AdhesiveBanner> {
		const query = {
            "type": "adhesive-banners",
            'metadata.atad_clients':'67cfaef40ed056126d2b27ae',
            'metadata.regions': stateId
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query))
            .set("props", "metadata.animated_carousel.item,metadata.animated_carousel.small_icon.imgix_url,"
				+"metadata.background_color,metadata.button_color,metadata.button_text,metadata.description,"
				+"metadata.description_color,metadata.description_font_size,metadata.heading,metadata.heading_color,"
				+"metadata.hide_for_dimers_pro_users,metadata.image_alt_text,metadata.logo_url,metadata.logo.imgix_url,"
				+"metadata.max_width,metadata.provider.slug")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
			// timeout({first: 5000, meta: {methodName: "getSportsBooksData"}}),
            map(data => {
				if (data?.objects?.[0]) {
					const b = data.objects?.[0];
					return {
						...b,
						...b.metadata
					};
				} else if (data && (data.objects === null || !data.total)) {
					return null;
				} else if (data.errors) {
					throw new Error(data.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
				
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
	}

	// getLatestArticleCategories(): Observable<Array<string>> {
	// 	return this.http.get<any>(https://api.cosmicjs.com/v1/insiderapi/objects?type=stats-insider-articles&sort=originally_published&limit=1&props=metafields")
	// 	.pipe(
        // timeout({first: 5000, meta: {methodName: "getLatestArticleCategories"}}),
	// 		map((response: any) => {
	// 			if (response && response.objects && response.objects[0]
	// 				&& response.objects[0].metafields && response.objects[0].metafields.some(m => m.key === "categories")) {
	// 				return response.objects[0].metafields.find(m => m.key === "categories").options.map(o => o.value);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Array<Record<string, any>>>())
	// 	)
	// }

	// getWelcomeOffers(state: string, count?: number, bookmakerID?: number): Observable<Array<BookOffer>> {
	// 	return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/welcome-promo?${count ? `limit=${count}&` : ``}state=${state}${bookmakerID? `&filter[onlySportsBook]=${bookmakerID}` : ""}`)
	// 		.pipe(
	// 			// timeout({first: 5000, meta: {methodName: "getWelcomeOffers"}}),
	// 			map((response: any) => {
	// 				if (response.data) {
	// 					return (response.data as Array<BookOffer>);
	// 				} else if (response.errors) {
	// 					throw new Error(response.errors[0].message)
	// 				} else {
	// 					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 				}
	// 			}),
	// 			catchError(this.handleError<Array<BookOffer>>())
	// 		)
	// }

	// getBestOffers(count: number, state: string, sportCode?: string): Observable<Array<BookOffer>> {
	// 	return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/best-promo?limit=${count}&state=${state}${
	// 		sportCode ? `&sport_league=${sportCode.toUpperCase()
	// 	}` : ""}`)
	// 		.pipe(
	// 			// timeout({first: 5000, meta: {methodName: "getBestOffers"}}),
	// 			map((response: any) => {
	// 				if (response.data) {
	// 					return (response.data as Array<BookOffer>);
	// 				} else if (response.errors) {
	// 					throw new Error(response.errors[0].message)
	// 				} else {
	// 					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 				}
	// 			}),
	// 			catchError(this.handleError<Array<BookOffer>>())
	// 		)
	// }

	getAppSettings(pageCode: string): Observable<PageMetaObject> {
		let rno = Math.random()
		console.log('getAppSettings');
		console.time('getAppSettings' + rno);
		return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +
			`/objects?query=%7B%22type%22%3A%22page-meta-objects%22%2C%22slug%22%3A%22${"dimers-" + pageCode}%22%7D&read_key=${this.cosmicReadKey}&props=slug,title,content,metadata&depth=2`)
		.pipe(
			map((response: any) => {
				if (response.objects) {
					console.log("Page meta returned:")
					console.timeLog('getAppSettings' + rno)
					const returnedObject = {
						...response.objects[0],
						...response.objects[0].metadata,
						app_title: response.objects[0].metadata.app_title || response.objects[0].metadata.seo_title,
						page_title: response.objects[0].title,
						thumbnail: {
							url: response.objects[0].metadata?.meta_image?.url || undefined,
						}
					};
					console.log("Page meta converted:");
					console.timeLog('getAppSettings' + rno)
					return returnedObject;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<PageMetaObject>())
		)
	}

	getBetHubSettings(sportCode: string): Observable<PageMetaObject> {
		return this.getAppSettings(`${sportCode.toLowerCase()}-predictions`)
			.pipe(
				map(settings => ({
					...settings,
					// site_title: settings.app_title,
					// seo_scheduled_title: settings.seo_title,
				}))
			)
	}

	getMatchMeta(matchID: string): Observable<Record<string, any>> {
		const sportCode = matchID.split("_")[0].toUpperCase();
		let bookmakerList: Array<string> = [];
		if (this.localisationService.getLocaleObject().sportExclusiveBookmakers
			&& Object.keys(this.localisationService.getLocaleObject().sportExclusiveBookmakers).includes(sportCode.toUpperCase())) {
			bookmakerList = [this.localisationService.getLocaleObject().sportExclusiveBookmakers[sportCode.toUpperCase()]];
		} else {
			bookmakerList = BOOKMAKERS;
		}

		if (sportCode === "NFL") {
			let match: Match, matchName: string, title: string, description: string, match_subheading_title: string, match_subheader: string, match_subheading_description: string, match_header: string;
			let faqs: Array<{headerText: string, bodyHTML: string}> = [];
					
			return this.sportDataService.getPreMatchData(matchID, true, bookmakerList)
			.pipe(
				concatMap((m: Match) => {
					match = m;
					const dateObject = parseJSON(match.MatchData.Date)
					const usEasternDateWithDay = dateObject.toLocaleDateString("en-US", {
						timeZone: "America/New_York",
						weekday: 'long',
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					});
					const usEasternDate = dateObject.toLocaleDateString("en-US", {
						timeZone: "America/New_York",
						dateStyle: "long",
					});
					const usEasternDateShort = dateObject.toLocaleDateString("en-US", {
						timeZone: "America/New_York"
					});
					const usEasternTime = dateObject.toLocaleTimeString("en-US", {
						timeZone: "America/New_York",
						hour: 'numeric', minute: '2-digit'
					})
					const usEasternDayOfWeek = dateObject.toLocaleDateString("en-US", {
						weekday: 'long',
					})

					// number indicating which team or player is more likely to win H2H -
					// 1 for home team or first player, 2 for away team or second player, 0 for dead heat or not set
					let moreLikelyWinner: number = 0;
					if (match.MatchData.Sport === "TEN") {
						if (match.PreData.H2HData.player1.winProb !== match.PreData.H2HData.player2.winProb) {
							moreLikelyWinner = match.PreData.H2HData.player1.winProb > match.PreData.H2HData.player2.winProb ? 1 : 2;
						}
					} else {
						if (match.PreData.PythagHome !== match.PreData.PythagAway) {
							moreLikelyWinner = match.PreData.PythagHome > match.PreData.PythagAway ? 1 : 2;
						}
					}

					const homeBeforeAway = ["EPL", "ESP"].includes(match.MatchData.Sport);
					const matchName = match.MatchData.Sport === "TEN" ?
						`${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last} vs. ${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}`
						: (homeBeforeAway ? `${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} vs. ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}` : `${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} vs. ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}`)

					match.MatchData.UserDate = parseJSON(match.MatchData.Date);
					description = `Get the latest ${matchName} predictions for the ${this.generalService.getShortName(match.MatchData.Sport)} game on ${usEasternDate}. Our model simulates the game 10,000 times for accurate picks and predictions. See who's favored to win here.`;


					if (this.matchService.isUpcoming(match)) {
						return from(import("./match-page-seo-template/nfl-upcoming-match").then(m => m.handleUpcomingMatch(
							matchName,
							usEasternDate,
							usEasternTime,
							usEasternDateShort,
							usEasternDateWithDay,
							this.generalService.teamNameDisplay(match.MatchData.AwayTeam),
							this.generalService.teamNameDisplay(match.MatchData.HomeTeam),
							this.betService.formatOddsDefault(match.aggregatedBettingInfo.AwayOdds),
							this.betService.formatOddsDefault(match.aggregatedBettingInfo.HomeOdds),
							this.generalService.getShortName(match.MatchData.Sport),
							match
						)));
					}
					if (this.isLivePipe.transform(match)) {
						return from(import("./match-page-seo-template/nfl-live-match").then(m => m.handleLiveMatch(
							matchName,
							usEasternDate,
							usEasternTime,
							usEasternDateShort,
							usEasternDateWithDay,
							this.generalService.teamNameDisplay(match.MatchData.AwayTeam),
							this.generalService.teamNameDisplay(match.MatchData.HomeTeam),
							this.betService.formatOddsDefault(match.aggregatedBettingInfo.AwayOdds),
							this.betService.formatOddsDefault(match.aggregatedBettingInfo.HomeOdds),
							this.generalService.getShortName(match.MatchData.Sport),
							match
						)));
					}
					return from(import("./match-page-seo-template/nfl-finish-match").then(m => m.handleOverMatch(
						matchName,
						usEasternDate,
						usEasternTime,
						usEasternDateShort,
						usEasternDateWithDay,
						this.generalService.teamNameDisplay(match.MatchData.AwayTeam),
						this.generalService.teamNameDisplay(match.MatchData.HomeTeam),
						this.betService.formatOddsDefault(match.aggregatedBettingInfo.AwayOdds),
						this.betService.formatOddsDefault(match.aggregatedBettingInfo.HomeOdds),
						this.generalService.getShortName(match.MatchData.Sport),
						match
					)));
					
				}),
				map((result) => {

					title = result.title;
					match_header =  result.match_header;
					match_subheader = result.match_subheader;
					match_subheading_description = result.match_subheading_description;
                        
					return {
						title: title,
						match_id: match.MatchData.SIMatchID,
						match_header: 
                            match.MatchData.Sport === "SOO"
                            ? `${matchName} Game ${match.MatchData.RoundNumber}` 
                            :  match.MatchData.Sport === "NFL"
                            ? `${match_header}`
                            : `${matchName} Prediction, Picks and Odds`,

						match_subheading_title: match_subheading_title || null,
						match_subheader: match_subheader || `<strong>Predictions</strong> and <strong>picks</strong> for ${matchName} on ${format(parseJSON(match.MatchData.Date), "iii MMM d, yyyy")}, including <a routerLink='/best-bets'>best bets</a>, <strong>betting odds</strong> and <a routerLink='/live-now'>live updates</a>.`,
						match_subheading_description: match_subheading_description || null,
						default_description: description,
						home_team_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last}` : match.MatchData.HomeTeam.DisplayName,
						visiting_team_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}` : match.MatchData.AwayTeam.DisplayName,
                        home_team_nick_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last}` : this.generalService.teamNameDisplay(match.MatchData.HomeTeam),
						visiting_team_nick_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}` : this.generalService.teamNameDisplay(match.MatchData.AwayTeam),
						match_date: match.MatchData.Date,
						venue: match.MatchData.Sport === "TEN" ? match.MatchData.TournamentName : match.MatchData.Venue,
						description: description,
						// thumbnail: {url: "https://cdn.ciphersports.io/images/generic_match_page_meta.jpg"},
						// thumbnail: {url: environment.defaultMetaImage},
						faqs: faqs,
					}
				})
			)
		}

		return this.sportDataService.getPreMatchData(matchID, true, bookmakerList)
			.pipe(
				map((match: Match) => {
					let title: string, description: string, match_subheading_title: string, match_subheader: string, match_subheading_description: string, match_header: string;
					let faqs: Array<{headerText: string, bodyHTML: string}> = [];
					const dateObject = parseJSON(match.MatchData.Date)
					const usEasternDateWithDay = dateObject.toLocaleDateString("en-US", {
						timeZone: "America/New_York",
						weekday: 'long',
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					});
					const usEasternDate = dateObject.toLocaleDateString("en-US", {
						timeZone: "America/New_York",
						dateStyle: "long",
					});
					const usEasternDateShort = dateObject.toLocaleDateString("en-US", {
						timeZone: "America/New_York"
					});
					const usEasternTime = dateObject.toLocaleTimeString("en-US", {
						timeZone: "America/New_York",
						hour: 'numeric', minute: '2-digit'
					})
					const usEasternDayOfWeek = dateObject.toLocaleDateString("en-US", {
						weekday: 'long',
					})

					// number indicating which team or player is more likely to win H2H -
					// 1 for home team or first player, 2 for away team or second player, 0 for dead heat or not set
					let moreLikelyWinner: number = 0;
					if (match.MatchData.Sport === "TEN") {
						if (match.PreData.H2HData.player1.winProb !== match.PreData.H2HData.player2.winProb) {
							moreLikelyWinner = match.PreData.H2HData.player1.winProb > match.PreData.H2HData.player2.winProb ? 1 : 2;
						}
					} else {
						if (match.PreData.PythagHome !== match.PreData.PythagAway) {
							moreLikelyWinner = match.PreData.PythagHome > match.PreData.PythagAway ? 1 : 2;
						}
					}

					const homeBeforeAway = ["EPL", "ESP"].includes(match.MatchData.Sport);
					const matchName = match.MatchData.Sport === "TEN" ?
						`${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last} vs. ${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}`
						: (homeBeforeAway ? `${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} vs. ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}` : `${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} vs. ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}`)

					if (match.MatchData.Sport.toUpperCase() === "CFB") {
						title = `${matchName} Prediction by Proven Computer Model [${usEasternDateShort}]`;
						description = `Our expert computer model has simulated the result of ${matchName} 10,000 times, offering the most up-to-date predictions, picks and betting odds for the NCAAF game on ${usEasternDate}. See who's favored to win and gain unique insights with our data-driven analysis.`;
						match_subheader = `<p>Our in-depth analysis offers a comprehensive prediction for the <strong>${matchName}</strong> NCAAF game on ${usEasternDate}. Drawing from 10,000 simulations, we provide expert picks, betting odds, and insights.</p>`;
						
						match_subheading_description = `
							<h2>${matchName}: Detailed Breakdown</h2>
							<h3>Matchup Overview</h3>
							<ul>
								<li><strong>Teams:</strong> ${matchName}</li>
								<li><strong>Date:</strong> ${usEasternDateWithDay}</li>
								<li><strong>Time:</strong> ${usEasternTime} ET</li>
								<li><strong>Venue:</strong> ${match.MatchData.Venue}</li>
							</ul>
							${match.aggregatedBettingInfo ? `
								<h3>Current Betting Odds</h3>
								<ul>
									<li><strong>Spread:</strong> ${match.aggregatedBettingInfo.HomeLine > 0
										? `${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} favored at ${match.aggregatedBettingInfo.HomeLine * -1}`
										: `${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} favored at ${match.aggregatedBettingInfo.HomeLine}`}</li>
									<li><strong>Total (Over/Under):</strong> ${match.aggregatedBettingInfo.TotalLine || 'Not Available'}</li>
									<li><strong>Moneyline Odds:</strong> ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.AwayOdds)}, ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.HomeOdds)}</li>
								</ul>
								<h3>Dimers' Win Probabilities</h3>
								<ul>
									<li><strong>${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}:</strong> ${(match.PreData.PythagAway * 100).toFixed(0)}%</li>
									<li><strong>${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}:</strong> ${(match.PreData.PythagHome * 100).toFixed(0)}%</li>
								</ul>
								<p><a href="/">Dimers.com</a>'s predictive model estimates win probabilities after simulating the outcome of the game 10,000 times. This method provides a precise and unbiased view of each team's chances.</p>
								<h3>Projected Final Score</h3>
								<p>Our predicted final score for this college football matchup is <strong>${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${match.PreData.PredAwayScore.toFixed(0)}-${match.PreData.PredHomeScore.toFixed(0)} ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}</strong>.</p>
								<p>This prediction is based on each team's average score following 10,000 game simulations.</p>


								
								${/*match.aggregatedBestBets ? `
									<h2>Top Betting Picks for ${matchName}</h2>
									<p>We've aligned our data-driven forecasts with the best available betting lines to identify high-value bets:</p>
									<ul>
										${match.aggregatedBestBets.some(b => b.type === "line") ? `
											<li><strong>Best Spread Bet:</strong> ${match.aggregatedBestBets.find(b => b.type === "line").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}
												${match.aggregatedBestBets.find(b => b.type === "line").lineValue > 0 ? '+' : ''}${match.aggregatedBestBets.find(b => b.type === "line").lineValue} @ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "line").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "line").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "line").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										${match.aggregatedBestBets.some(b => b.type === "h2h") ? `
											<li><strong>Best Moneyline Bet:</strong> ${match.aggregatedBestBets.find(b => b.type === "h2h").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}
												@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "h2h").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "h2h").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "h2h").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										${match.aggregatedBestBets.some(b => b.type === "total") ? `
											<li><strong>Best Total Bet:</strong> ${this.titleCasePipe.transform(match.aggregatedBestBets.find(b => b.type === "total").bet)} ${match.aggregatedBestBets.find(b => b.type === "total").markValue}
												@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "total").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "total").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "total").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										
									</ul>
									<p>Our approach occasionally uncovers the less-favored option or team that offers a betting edge, which is crucial for long-term betting success.</p>
								` :*/ ""}
							` : ""}
							


							${/*<h2>Comprehensive Analysis of ${matchName}</h2>
							<p>Our specialized model for NCAAF games, coupled with rigorous testing and analysis, identifies betting opportunities where the odds favor the bettors.</p>
							<p>We dive beyond surface-level predictions, offering a full examination of the ${matchName} matchup.</p>
							<h3>Detailed Insights for Bettors and Fans</h3>
							<p>Whether you're a seasoned bettor or a die-hard fan, our predictions cater to your needs, covering all aspects of the ${matchName} game.</p>
							<h3>Trustworthy Predictions</h3>
							<p>Our predictions stand out due to the proven accuracy and reliability of our advanced computer model, making Dimers a trusted source for NCAAF betting insights.</p>
							<h3>Updated Predictions</h3>
							<p>We continually update our predictions, integrating the latest data and trends for the most relevant and current information.</p>
							<h3>Responsible Betting</h3>
							<p>We advocate for responsible gambling practices. Visit our <a href="/responsible-gambling">responsible gambling</a> page for more information and resources.</p>
							*/ ""}


							${/*match.aggregatedBettingInfo && match.aggregatedBestBets && match.aggregatedBestBets.some(b => b.type === "line") ? `
								<h2>Our Spread Pick for ${matchName}</h2>
								<p>Our innovative system, based on thousands of simulations, suggests a statistical edge with our pick against the spread: ${match.aggregatedBestBets.find(b => b.type === "line").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}
								${match.aggregatedBestBets.find(b => b.type === "line").lineValue > 0 ? '+' : ''}${match.aggregatedBestBets.find(b => b.type === "line").lineValue} @ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "line").odds)} via
								${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "line").bookmaker)}
								(${(match.aggregatedBestBets.find(b => b.type === "line").winProb * 100).toFixed(0)}% probability).</p>
							` :*/ ""}
							


							${/*<h2>Live Updates: ${matchName} Game Day</h2>
							<p>For real-time predictions and game updates, Dimers is your go-to source. Track live scores and win probabilities on ${usEasternDate}, directly from ${match.MatchData.Venue}.</p>
							*/ ""}

							<h2>Our Prediction: Who Wins?</h2>
							<p>After extensive simulations, our model gives ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} a win probability of ${(match.PreData.PythagAway * 100).toFixed(0)}%, while ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} has a win probability of ${(match.PreData.PythagHome * 100).toFixed(0)}%.</p>
							
							<h2>Summary</h2>

							${moreLikelyWinner ? `
								<p>According to our analysis, ${this.generalService.teamNameDisplay(moreLikelyWinner === 1 ? match.MatchData.HomeTeam : match.MatchData.AwayTeam)} is more likely to beat ${this.generalService.teamNameDisplay(moreLikelyWinner === 1 ? match.MatchData.AwayTeam : match.MatchData.HomeTeam)} in CFB action at ${match.MatchData.Venue} on ${usEasternDayOfWeek}. Discover the best spread, over/under and moneyline odds, picks and probabilities for the game on this page.</p>
							` : `
								<p>According to our analysis, ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} and ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} have an equal chance of winning in CFB action at ${match.MatchData.Venue} on ${usEasternDayOfWeek}. Discover the best spread, over/under and moneyline odds, picks and probabilities for the game on this page.</p>
							`}

							<p>Our ${matchName} predictions, based on sophisticated simulations and current data, guide you in making informed decisions. Remember to bet responsibly and within your financial limits.</p>
							<p>For additional resources and advice on responsible gambling, please call 1-800-GAMBLER.</p>
							<p>When you click or tap on a link on Dimers that goes to a third-party website that we have a commercial relationship with (such as a sportsbook), we may receive a referral fee.</p>
							<p>Explore our expert <a href="/bet-hub/cfb/schedule">college football predictions</a> and the <a href="/best-bets/cfb">best college football bets today</a> for more insights and betting opportunities.</p>
							<p>Our in-depth analysis includes <a href="/best-props/cfb">college football player props</a> and <a href="/futures/cfb">college football futures</a>, as well as constantly updated <a href="/cfb/best-odds">college football odds</a> and the latest <a href="/news?sport=cfb">college football news</a>.</p>
							
						`;
					} else if (match.MatchData.Sport.toUpperCase() === "CBB") {
						title = `${matchName} Prediction by Proven Computer Model [${usEasternDateShort}]`;
						description = `Our expert computer model has simulated the result of ${matchName} 10,000 times, offering the most up-to-date predictions, picks and betting odds for the NCAAB game on ${usEasternDate}. See who's favored to win and gain unique insights with our data-driven analysis.`;
						match_subheader = `<p>Our in-depth analysis offers a comprehensive prediction for the <strong>${matchName}</strong> NCAAB game on ${usEasternDate}. Drawing from 10,000 simulations, we provide expert picks, betting odds, and insights.</p>`;
						
						match_subheading_description = `
							<h2>${matchName}: Detailed Breakdown</h2>
							<h3>Matchup Overview</h3>
							<ul>
								<li><strong>Teams:</strong> ${matchName}</li>
								<li><strong>Date:</strong> ${usEasternDateWithDay}</li>
								<li><strong>Time:</strong> ${usEasternTime} ET</li>
								<li><strong>Venue:</strong> ${match.MatchData.Venue}</li>
							</ul>
							${match.aggregatedBettingInfo ? `
								<h3>Current Betting Odds</h3>
								<ul>
									<li><strong>Spread:</strong> ${match.aggregatedBettingInfo.HomeLine > 0
										? `${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} favored at ${match.aggregatedBettingInfo.HomeLine * -1}`
										: `${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} favored at ${match.aggregatedBettingInfo.HomeLine}`}</li>
									<li><strong>Total (Over/Under):</strong> ${match.aggregatedBettingInfo.TotalLine || 'Not Available'}</li>
									<li><strong>Moneyline Odds:</strong> ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.AwayOdds)}, ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.HomeOdds)}</li>
								</ul>
								<h3>Dimers' Win Probabilities</h3>
								<ul>
									<li><strong>${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}:</strong> ${(match.PreData.PythagAway * 100).toFixed(0)}%</li>
									<li><strong>${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}:</strong> ${(match.PreData.PythagHome * 100).toFixed(0)}%</li>
								</ul>
								<p><a href="/">Dimers.com</a>'s predictive model estimates win probabilities after simulating the outcome of the game 10,000 times. This method provides a precise and unbiased view of each team's chances.</p>
								<h3>Projected Final Score</h3>
								<p>Our predicted final score for this college basketball matchup is <strong>${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${match.PreData.PredAwayScore.toFixed(0)}-${match.PreData.PredHomeScore.toFixed(0)} ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}</strong>.</p>
								<p>This prediction is based on each team's average score following 10,000 game simulations.</p>


								
								${/*match.aggregatedBestBets ? `
									<h2>Top Betting Picks for ${matchName}</h2>
									<p>We've aligned our data-driven forecasts with the best available betting lines to identify high-value bets:</p>
									<ul>
										${match.aggregatedBestBets.some(b => b.type === "line") ? `
											<li><strong>Best Spread Bet:</strong> ${match.aggregatedBestBets.find(b => b.type === "line").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}
												${match.aggregatedBestBets.find(b => b.type === "line").lineValue > 0 ? '+' : ''}${match.aggregatedBestBets.find(b => b.type === "line").lineValue} @ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "line").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "line").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "line").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										${match.aggregatedBestBets.some(b => b.type === "h2h") ? `
											<li><strong>Best Moneyline Bet:</strong> ${match.aggregatedBestBets.find(b => b.type === "h2h").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}
												@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "h2h").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "h2h").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "h2h").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										${match.aggregatedBestBets.some(b => b.type === "total") ? `
											<li><strong>Best Total Bet:</strong> ${this.titleCasePipe.transform(match.aggregatedBestBets.find(b => b.type === "total").bet)} ${match.aggregatedBestBets.find(b => b.type === "total").markValue}
												@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "total").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "total").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "total").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										
									</ul>
									<p>Our approach occasionally uncovers the less-favored option or team that offers a betting edge, which is crucial for long-term betting success.</p>
								` : */ ""}
							` : ""}
							


							${/*<h2>Comprehensive Analysis of ${matchName}</h2>
							<p>Our specialized model for NCAAB games, coupled with rigorous testing and analysis, identifies betting opportunities where the odds favor the bettors.</p>
							<p>We dive beyond surface-level predictions, offering a full examination of the ${matchName} matchup.</p>
							<h3>Detailed Insights for Bettors and Fans</h3>
							<p>Whether you're a seasoned bettor or a die-hard fan, our predictions cater to your needs, covering all aspects of the ${matchName} game.</p>
							<h3>Trustworthy Predictions</h3>
							<p>Our predictions stand out due to the proven accuracy and reliability of our advanced computer model, making Dimers a trusted source for NCAAB betting insights.</p>
							<h3>Updated Predictions</h3>
							<p>We continually update our predictions, integrating the latest data and trends for the most relevant and current information.</p>
							<h3>Responsible Betting</h3>
							<p>We advocate for responsible gambling practices. Visit our <a href="/responsible-gambling">responsible gambling</a> page for more information and resources.</p>
							*/ ""}

							${/*match.aggregatedBettingInfo && match.aggregatedBestBets && match.aggregatedBestBets.some(b => b.type === "line") ? `
								<h2>Our Spread Pick for ${matchName}</h2>
								<p>Our innovative system, based on thousands of simulations, suggests a statistical edge with our pick against the spread: ${match.aggregatedBestBets.find(b => b.type === "line").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}
								${match.aggregatedBestBets.find(b => b.type === "line").lineValue > 0 ? '+' : ''}${match.aggregatedBestBets.find(b => b.type === "line").lineValue} @ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "line").odds)} via
								${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "line").bookmaker)}
								(${(match.aggregatedBestBets.find(b => b.type === "line").winProb * 100).toFixed(0)}% probability).</p>
							` :*/ ""}
							


							${/* <h2>Live Updates: ${matchName} Game Day</h2>
							<p>For real-time predictions and game updates, Dimers is your go-to source. Track live scores and win probabilities on ${usEasternDate}, directly from ${match.MatchData.Venue}.</p>
							*/ ""}

							<h2>Our Prediction: Who Wins?</h2>
							<p>After extensive simulations, our model gives ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} a win probability of ${(match.PreData.PythagAway * 100).toFixed(0)}%, while ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} has a win probability of ${(match.PreData.PythagHome * 100).toFixed(0)}%.</p>
							<h2>Summary</h2>

							${moreLikelyWinner ? `
								<p>According to our analysis, ${this.generalService.teamNameDisplay(moreLikelyWinner === 1 ? match.MatchData.HomeTeam : match.MatchData.AwayTeam)} is more likely to beat ${this.generalService.teamNameDisplay(moreLikelyWinner === 1 ? match.MatchData.AwayTeam : match.MatchData.HomeTeam)} in CBB action at ${match.MatchData.Venue} on ${usEasternDayOfWeek}. Discover the best spread, over/under and moneyline odds, picks and probabilities for the game on this page.</p>
							` : `
								<p>According to our analysis, ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} and ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} have an equal chance of winning in CBB action at ${match.MatchData.Venue} on ${usEasternDayOfWeek}. Discover the best spread, over/under and moneyline odds, picks and probabilities for the game on this page.</p>
							`}

							<p>Our ${matchName} predictions, based on sophisticated simulations and current data, guide you in making informed decisions. Remember to bet responsibly and within your financial limits.</p>
							<p>For additional resources and advice on responsible gambling, please call 1-800-GAMBLER.</p>
							<p>When you click or tap on a link on Dimers that goes to a third-party website that we have a commercial relationship with (such as a sportsbook), we may receive a referral fee.</p>
							<p>Explore our expert <a href="/bet-hub/cbb/schedule">college basketball predictions</a> and the <a href="/best-bets/cbb">best college basketball bets today</a> for more insights and betting opportunities.</p>
							<p>Our in-depth analysis includes <a href="/best-props/cbb">college basketball player props</a> and <a href="/futures/cbb">college basketball futures</a>, as well as constantly updated <a href="/cbb/best-odds">college basketball odds</a> and the latest <a href="/news?sport=cbb">college basketball news</a>.</p>
							
						`;
					} else if (match.MatchData.Sport.toUpperCase() === "TEN") {
						const tournamentName = match.MatchData.TournamentName?.replace("Men", "Men's").replace("Women", "Women's");
						const player1Name = `${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last}`;
						const player2Name = `${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}`;
						title = `${matchName} Prediction for ${tournamentName}`;
						description = `Our expert computer model has simulated the result of ${matchName} 10,000 times, offering the most up-to-date predictions, picks and betting odds for the ${tournamentName} match. See who's favored to win and gain unique insights with our data-driven analysis.`;
						match_subheader = `<strong>${player1Name}</strong> faces <strong>${player2Name}</strong> in the <strong>${tournamentName}</strong> on ${usEasternDate}. Drawing from 10,000 simulations, we provide expert picks, betting odds, and insights.`;
						match_subheading_description = `
							<h2>${matchName}: Detailed Breakdown</h2>
							<h3>Match Overview</h3>
							<ul>
								<li><strong>Who:</strong> ${matchName}</li>
								<li><strong>Date:</strong> ${usEasternDateWithDay}</li>
								<li><strong>Approx. Time:</strong> ${usEasternTime} ET</li>
								<li><strong>Tournament:</strong> ${tournamentName}</li>
							</ul>

							${match.aggregatedBettingInfo ? `
								<h3>Current Betting Odds</h3>
								<ul>
									<li><strong>Moneyline Odds:</strong> ${player1Name} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.H2HOdds.player1.odds)}, ${player2Name} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.H2HOdds.player2.odds)}</li>
									${match.aggregatedBettingInfo.FirstSetOdds ? `
										<li><strong>First Set Odds:</strong> ${player1Name} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.FirstSetOdds.p1Odds)}, ${player2Name} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.FirstSetOdds.p2Odds)}</li>
									` : ""}
								</ul>
							` : ""}
							
							<h3>Dimers' Win Probabilities</h3>
							<ul>
								<li><strong>${player1Name}:</strong> ${(match.PreData.H2HData.player1.winProb * 100).toFixed(1)}%</li>
								<li><strong>${player2Name}:</strong> ${(match.PreData.H2HData.player2.winProb * 100).toFixed(1)}%</li>
							</ul>

							<p><a href="/">Dimers.com</a>'s predictive model provides an unbiased view of each player's winning chances after simulating the outcome of the match 10,000 times.</p>
							${/*match.aggregatedBestBets?.length > 0 ? `
								<h2>Top Betting Picks for ${matchName}</h2>
								<p>We've aligned our data-driven forecasts with the best available betting lines to identify high-value bets:</p>
								<ul>
									${match.aggregatedBestBets.some(b => b.type === "h2h") ? `
										<li>
											<strong>Best Moneyline Bet:</strong>
											${match.aggregatedBestBets.find(b => b.type === "h2h").bet === "home" ? player1Name : player2Name}
											@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "h2h").odds)}
											via ${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "h2h").bookmaker)}
											(${(match.aggregatedBestBets.find(b => b.type === "h2h").winProb * 100).toFixed(0)}% probability)
										</li>
									` : ""}
									${match.aggregatedBestBets.some(b => b.type === "firstset") ? `
										<li>
											<strong>Best First Set Bet:</strong>
											${match.aggregatedBestBets.find(b => b.type === "firstset").bet === "home" ? player1Name : player2Name}
											@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "firstset").odds)}
											via ${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "firstset").bookmaker)}
											(${(match.aggregatedBestBets.find(b => b.type === "firstset").winProb * 100).toFixed(0)}% probability)
										</li>
									` : ""}
								</ul>
								<p>Our approach occasionally uncovers the less-favored option or player that offers a betting edge, which is crucial for long-term betting success.</p>
							` : */""}
							
							${/*<h2>Comprehensive Analysis of ${matchName} Picks</h2>
							<p>Our specialized model for tennis matches, coupled with rigorous testing and analysis, identifies betting opportunities where the odds favor the bettors.</p>
							<p>We dive beyond surface-level predictions, offering a full examination of the ${matchName} match.</p>
							
							<h3>Detailed Insights for Bettors and Fans</h3>
							<p>Whether you're a seasoned bettor or an avid tennis fan, our predictions cater to your needs, covering all aspects of the ${tournamentName} tournament.</p>
							
							<h3>Trustworthy Predictions</h3>
							<p>Our predictions stand out due to the proven accuracy and reliability of our advanced computer model, making Dimers a trusted source for tennis betting insights.</p>
							
							<h3>Updated Predictions</h3>
							<p>We continually update our ${tournamentName} predictions, integrating the latest data and trends for the most relevant and current information.</p>
							
							<h3>Responsible Betting</h3>
							<p>We advocate for responsible gambling practices. Visit our <a href="/responsible-gambling">responsible gambling</a> page for more information and resources.</p>
							
							<h2>Live Updates: ${matchName}</h2>
							<p>For real-time predictions and game updates, Dimers is your go-to source. Track ${tournamentName} live scores and win probabilities here on ${usEasternDate}.</p>
							*/ ""}
							
							<h2>Our Prediction: Who Wins?</h2>
							<p>After extensive simulations, our model gives ${player1Name} a win probability of ${(match.PreData.H2HData.player1.winProb * 100).toFixed(0)}%, while ${player2Name} has a win probability of ${(match.PreData.H2HData.player2.winProb * 100).toFixed(0)}%.</p>
							
							<h2>Summary</h2>
							${moreLikelyWinner ? `
								<p>According to our analysis, ${moreLikelyWinner === 1 ? player1Name : player2Name} is more likely to beat ${moreLikelyWinner === 1 ? player2Name : player1Name} at the ${tournamentName} on ${usEasternDayOfWeek}.</p>
							` : `
								<p>According to our analysis, ${player1Name} and ${player2Name} have an equal chance of winning their match at the ${tournamentName} on ${usEasternDayOfWeek}.</p>
							`}
							<p>Our ${matchName} predictions, based on sophisticated simulations and current data, guide you in making informed decisions. Remember to bet responsibly and within your financial limits.</p>
							<p>For additional resources and advice on responsible gambling, please call 1-800-GAMBLER.</p>
							<p>When you click or tap on a link on Dimers that goes to a third-party website that we have a commercial relationship with (such as a sportsbook), we may receive a referral fee.</p>
							<p>Explore our expert <a href="/bet-hub/ten/schedule">tennis predictions</a> and the <a href="/best-bets/ten">best tennis bets today</a> for more insights and betting opportunities. Our in-depth analysis includes <a href="/news?sport=ten">tennis news</a> and <a href="/bet-hub/ten/rankings">tennis world rankings</a>.</p>
						`;
					} else if (this.generalService.isSoccer(match.MatchData.Sport.toUpperCase())) {
						let shortVenue = match.MatchData.Venue;
						// remove any parentheses and content within from the venue name, including nested parentheses
						while (shortVenue != (shortVenue = shortVenue.replace(/\s*\([^()]*\)/g, ""))); 

						// find keys in the PreData which correspond to Correct Score probability data
						const correctScoreList = Object.keys(match.PreData).filter(k => k.match(/CS_([HAD])([0-9]+)_([0-9]+)_Pct/m)).map(k => ({key: k, value: match.PreData[k]}));
						const mostLikelyCorrectScore = correctScoreList.length === 0 ? null : correctScoreList.sort((a,b) => b.value - a.value)[0];
						let mostLikelyCorrectScoreHome: number;
						let mostLikelyCorrectScoreAway: number;

						// Determine the score tied to this instance from the key's pattern
						if (mostLikelyCorrectScore) {
							const scorePieces = mostLikelyCorrectScore.key.match(/CS_([HAD])([0-9]+)_([0-9]+)_Pct/m);
							// Home and away team scores are swapped in the key if the away team is the winner in this instance (indicated by "A")
							if (scorePieces[1] === "A") {
								mostLikelyCorrectScoreHome = parseInt(scorePieces[3], 10);
								mostLikelyCorrectScoreAway = parseInt(scorePieces[2], 10);
							} else {
								mostLikelyCorrectScoreHome = parseInt(scorePieces[2], 10);
								mostLikelyCorrectScoreAway = parseInt(scorePieces[3], 10);
							}
						}

						title = `${matchName} Prediction by Proven Computer Model [${usEasternDateShort}]`;
						description = `Get the latest ${matchName} predictions for the ${this.generalService.getShortName(match.MatchData.Sport)} game on ${usEasternDate}. Our model simulates the game 10,000 times for accurate picks and predictions. See who's favored to win here.`;
						match_subheader = `Our detailed prediction and analysis for the <strong>${matchName}</strong> ${this.generalService.getShortName(match.MatchData.Sport)} game on ${usEasternDate} is featured below. Drawing from 10,000 game simulations, we provide expert picks, betting odds, and insights.`;
						match_subheading_description = `
							<h2>${matchName}: Detailed Breakdown</h2>
							<h3>Matchup Overview</h3>
							<ul>
								<li><strong>Teams:</strong> ${matchName}</li>
								<li><strong>Date:</strong> ${usEasternDateWithDay}</li>
								<li><strong>Time:</strong> ${usEasternTime} ET</li>
								<li><strong>Venue:</strong> ${shortVenue}</li>
							</ul>
							${match.aggregatedBettingInfo ? `
								<h3>Current Betting Odds</h3>
								<ul>
									${homeBeforeAway ? `
										<li><strong>Moneyline Odds:</strong> ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.HomeOdds)}, ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.AwayOdds)}, Draw ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.DrawOdds)}</li>
									` : `
										<li><strong>Moneyline Odds:</strong> ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.AwayOdds)}, ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.HomeOdds)}, Draw ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.DrawOdds)}</li>
									`}
									${match.aggregatedBettingInfo.TotalLine ? `
										<li><strong>Total (Over/Under):</strong> ${match.aggregatedBettingInfo.TotalLine} (${this.betService.formatOddsDefault(match.aggregatedBettingInfo.OverOdds)}/${this.betService.formatOddsDefault(match.aggregatedBettingInfo.UnderOdds)})</li>
									` : ""}
									
								</ul>
							` : ""}
							

							<h3>Dimers' Win Probabilities</h3>
							<ul>
								${homeBeforeAway ? `
									<li><strong>${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}:</strong> ${(match.PreData.PythagHome * 100).toFixed(1)}%</li>
									<li><strong>${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}:</strong> ${(match.PreData.PythagAway * 100).toFixed(1)}%</li>
								` : `
									<li><strong>${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}:</strong> ${(match.PreData.PythagAway * 100).toFixed(1)}%</li>
									<li><strong>${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}:</strong> ${(match.PreData.PythagHome * 100).toFixed(1)}%</li>
								`}
								
								<li><strong>Draw:</strong> ${(match.PreData.PythagDraw * 100).toFixed(1)}%</li>
							</ul>

							<p><a href="/">Dimers.com</a>'s predictive model, renowned for its accuracy, assesses each team's chances by simulating the outcome of the game 10,000 times. This method provides a precise and unbiased view.</p>
							
							${mostLikelyCorrectScore ? `
								<h3>Projected Final Score</h3>
								<p>Our most likely correct score for this ${this.generalService.getShortName(match.MatchData.Sport)} game is
								<strong>${homeBeforeAway ? `${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} ${mostLikelyCorrectScoreHome}-${mostLikelyCorrectScoreAway} ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}`
									: `${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${mostLikelyCorrectScoreAway}-${mostLikelyCorrectScoreHome} ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}`}</strong>,
								which has a correct score probability of ${(mostLikelyCorrectScore.value * 100).toFixed(0)}%.</p>
								<p>This prediction is based on each team's average score following 10,000 game simulations. In some cases, a team can have a higher overall probability of winning, yet the most likely specific outcome (correct score) is a draw.</p>
							` : ""}
							
							${/*match.aggregatedBestBets?.length > 0 ? `
								<h2>Top Betting Picks for ${matchName}</h2>
								<p>We've aligned our data-driven forecasts with the best available betting lines to identify high-value bets:</p>
								<ul>
									${match.aggregatedBestBets.some(b => b.type === "h2h") ? `
										<li><strong>Best Moneyline Bet:</strong> ${match.aggregatedBestBets.find(b => b.type === "h2h").bet === "draw" ? "Draw" : (match.aggregatedBestBets.find(b => b.type === "h2h").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam))}
											@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "h2h").odds)} via
											${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "h2h").bookmaker)}
											(${(match.aggregatedBestBets.find(b => b.type === "h2h").winProb * 100).toFixed(0)}% probability)</li>
									` : ""}
									${match.aggregatedBestBets.some(b => b.type === "total") ? `
										<li><strong>Best Total Bet:</strong> ${this.titleCasePipe.transform(match.aggregatedBestBets.find(b => b.type === "total").bet)} ${match.aggregatedBestBets.find(b => b.type === "total").markValue}
											@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "total").odds)} via
											${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "total").bookmaker)}
											(${(match.aggregatedBestBets.find(b => b.type === "total").winProb * 100).toFixed(0)}% probability)</li>
									` : ""}
									
								</ul>

								<p>Our approach occasionally uncovers the less-favored option or team that offers a betting edge, which is crucial for long-term betting success.</p>
							` :*/ ""}
							
							
							${/*<h2>Comprehensive Analysis of ${matchName} Picks</h2>
							<p>Our specialized model for ${this.generalService.getShortName(match.MatchData.Sport)} games, coupled with rigorous testing and analysis, identifies betting opportunities where odds favor the informed bettor.</p>
							<p>We dive beyond surface-level predictions, offering a full examination of the ${matchName} match.</p>
							
							<h3>Detailed Insights for Bettors and Fans</h3>
							<p>Whether you're a seasoned bettor or a die-hard fan, our predictions cater to your needs, covering all aspects of the ${matchName} game.</p>
							
							<h3>Trustworthy Predictions</h3>
							<p>Our predictions stand out due to the proven accuracy and reliability of our advanced computer model, making Dimers a trusted source for ${this.generalService.getShortName(match.MatchData.Sport)} betting insights.</p>
							
							<h3>Updated Predictions</h3>
							<p>We continually update our ${matchName} predictions, integrating the latest data and trends for the most relevant and current information.</p>
							
							<h3>Responsible Betting</h3>
							<p>We advocate for responsible gambling practices. Visit our <a href="/responsible-gambling">responsible gambling</a> page for more information and resources.</p>
							
							<h2>Live Updates: ${matchName} Game Day</h2>
							<p>For real-time predictions and game updates, Dimers is your go-to source. Track live scores and win probabilities on ${usEasternDate}, directly from ${shortVenue}.</p>
							*/ ""}

							<h2>Our Prediction: Who Wins?</h2>
							<p>After extensive simulations, our model gives ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} a win probability of ${(match.PreData.PythagHome * 100).toFixed(1)}%, while ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} has a win probability of ${(match.PreData.PythagAway * 100).toFixed(1)}%. The chance of a draw is ${(match.PreData.PythagDraw * 100).toFixed(1)}%.</p>
							
							<h2>Summary</h2>
							<p>Our ${matchName} predictions, based on sophisticated simulations and current data, guide you in making informed decisions. Remember to bet responsibly and within your financial limits.</p>
							<p>For additional resources and advice on responsible gambling, please call 1-800-GAMBLER.</p>
							<p>When you click or tap on a link on Dimers that goes to a third-party website that we have a commercial relationship with (such as a sportsbook), we may receive a referral fee.</p>
							<p>For further insights, explore our expert <a href="https://www.dimers.com/bet-hub/${match.MatchData.Sport.toLowerCase()}/schedule">${this.generalService.getShortName(match.MatchData.Sport)} predictions</a> and the <a href="https://www.dimers.com/best-bets/${match.MatchData.Sport.toLowerCase()}">best ${this.generalService.getShortName(match.MatchData.Sport)} bets</a> today, while our in-depth analysis includes <a href="https://www.dimers.com/futures/${match.MatchData.Sport.toLowerCase()}">${this.generalService.getShortName(match.MatchData.Sport)} futures</a>, <a href="https://www.dimers.com/${match.MatchData.Sport.toLowerCase()}/best-odds">${this.generalService.getShortName(match.MatchData.Sport)} odds</a>, and the latest <a href="https://www.dimers.com/news?sport=${match.MatchData.Sport.toLowerCase()}">${this.generalService.getShortName(match.MatchData.Sport)} news</a>.</p>
						`;
                    }
                    else {
						const predictedScoreVisible = !["MLB", "NHL"].includes(match.MatchData.Sport);
						title = `${matchName} Prediction by Proven Computer Model [${usEasternDateShort}]`;
						description = `Get the latest ${matchName} predictions for the ${this.generalService.getShortName(match.MatchData.Sport)} game on ${usEasternDate}. Our model simulates the game 10,000 times for accurate picks and predictions. See who's favored to win here.`;
						match_subheader = `Our detailed prediction and analysis for the <strong>${matchName}</strong> ${this.generalService.getShortName(match.MatchData.Sport)} game on ${usEasternDate} is featured below. Drawing from 10,000 game simulations, we provide expert picks, betting odds, and insights.`;
						match_subheading_description = `
							<h2>${matchName}: Detailed Breakdown</h2>

							<h3>Matchup Overview</h3>
							<ul>
								<li><strong>Teams:</strong> ${matchName}</li>
								<li><strong>Date:</strong> ${usEasternDateWithDay}</li>
								<li><strong>Time:</strong> ${usEasternTime} ET</li>
								<li><strong>Venue:</strong> ${match.MatchData.Venue}</li>
							</ul>

							${match.aggregatedBettingInfo ? `
								<h3>Current Betting Odds</h3>
								<ul>
									<li><strong>Spread:</strong> ${match.aggregatedBettingInfo.HomeLine > 0
										? `${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} favored at ${match.aggregatedBettingInfo.HomeLine * -1}`
										: `${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} favored at ${match.aggregatedBettingInfo.HomeLine}`}</li>
									<li><strong>Total (Over/Under):</strong> ${match.aggregatedBettingInfo.TotalLine || 'Not Available'}</li>
									<li><strong>Moneyline Odds:</strong> ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.AwayOdds)}, ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} ${this.betService.formatOddsDefault(match.aggregatedBettingInfo.HomeOdds)}</li>
								</ul>
							` : ""}
							

							<h3>Dimers' Win Probabilities</h3>
							<ul>
								<li><strong>${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}:</strong> ${(match.PreData.PythagAway * 100).toFixed(0)}%</li>
								<li><strong>${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}:</strong> ${(match.PreData.PythagHome * 100).toFixed(0)}%</li>
							</ul>
							<p><a href="/">Dimers.com</a>'s predictive model, renowned for its accuracy, assesses each team's chances by simulating the outcome of the game 10,000 times. This method provides a precise and unbiased view.</p>
							
							${predictedScoreVisible ? `
								<h3>Projected Final Score</h3>
								<p>Our predicted final score for this ${this.generalService.getShortName(match.MatchData.Sport)} matchup is <strong>${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} ${match.PreData.PredAwayScore.toFixed(0)}-${match.PreData.PredHomeScore.toFixed(0)} ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)}</strong>.</p>
								<p>This prediction is based on each team's average score following 10,000 game simulations.</p>
							` : ""}
							

							${/*match.aggregatedBestBets ? `
									<h2>Top Betting Picks for ${matchName}</h2>
									<p>We've aligned our data-driven forecasts with the best available betting lines to identify high-value bets:</p>
									<ul>
										${match.aggregatedBestBets.some(b => b.type === "line") ? `
											<li><strong>Best Spread Bet:</strong> ${match.aggregatedBestBets.find(b => b.type === "line").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}
												${match.aggregatedBestBets.find(b => b.type === "line").lineValue > 0 ? '+' : ''}${match.aggregatedBestBets.find(b => b.type === "line").lineValue} @ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "line").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "line").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "line").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										${match.aggregatedBestBets.some(b => b.type === "h2h") ? `
											<li><strong>Best Moneyline Bet:</strong> ${match.aggregatedBestBets.find(b => b.type === "h2h").bet === "home" ? this.generalService.teamNameDisplay(match.MatchData.HomeTeam) : this.generalService.teamNameDisplay(match.MatchData.AwayTeam)}
												@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "h2h").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "h2h").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "h2h").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										${match.aggregatedBestBets.some(b => b.type === "total") ? `
											<li><strong>Best Total Bet:</strong> ${this.titleCasePipe.transform(match.aggregatedBestBets.find(b => b.type === "total").bet)} ${match.aggregatedBestBets.find(b => b.type === "total").markValue}
												@ ${this.betService.formatOddsDefault(match.aggregatedBestBets.find(b => b.type === "total").odds)} via
												${this.betService.stylisedBookName(match.aggregatedBestBets.find(b => b.type === "total").bookmaker)}
												(${(match.aggregatedBestBets.find(b => b.type === "total").winProb * 100).toFixed(0)}% probability)</li>
										` : ""}
										
									</ul>
									<p>Our approach occasionally uncovers the less-favored option or team that offers a betting edge, which is crucial for long-term betting success.</p>
								` :*/ ""}

							<h2>Our Prediction: Who Wins?</h2>
							<p>After extensive simulations, our model gives the ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} a win probability of ${(match.PreData.PythagAway * 100).toFixed(0)}%, while the ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} have a win probability of ${(match.PreData.PythagHome * 100).toFixed(0)}%. Discover the best spread, over/under, and moneyline odds for the game on this page.</p>
							
							<h2>Summary</h2>
							${moreLikelyWinner ? `
								<p>According to our analysis, the ${this.generalService.teamNameDisplay(moreLikelyWinner === 1 ? match.MatchData.HomeTeam : match.MatchData.AwayTeam)} are more likely to beat the ${this.generalService.teamNameDisplay(moreLikelyWinner === 1 ? match.MatchData.AwayTeam : match.MatchData.HomeTeam)} in the ${this.generalService.getShortName(match.MatchData.Sport)} game at ${match.MatchData.Venue} on ${usEasternDayOfWeek}. Discover the best spread, over/under and moneyline odds, picks and probabilities for the game on this page.</p>
							` : `
								<p>According to our analysis, the ${this.generalService.teamNameDisplay(match.MatchData.HomeTeam)} and the ${this.generalService.teamNameDisplay(match.MatchData.AwayTeam)} have an equal chance of winning the ${this.generalService.getShortName(match.MatchData.Sport)} game at ${match.MatchData.Venue} on ${usEasternDayOfWeek}. Discover the best spread, over/under and moneyline odds, picks and probabilities for the game on this page.</p>
							`}
							<p>Our ${matchName} predictions, based on sophisticated simulations and current data, guide you in making informed decisions. Remember to bet responsibly and within your financial limits.</p>
							<p>For additional resources and advice on responsible gambling, please call 1-800-GAMBLER.</p>
							<p>When you click or tap on a link on Dimers that goes to a third-party website that we have a commercial relationship with (such as a sportsbook), we may receive a referral fee.</p>
                            ${!["NRL", "AFL"].includes(match.MatchData.Sport) ? `
                                <p>For further insights, explore our expert <a href="https://www.dimers.com/bet-hub/${match.MatchData.Sport.toLowerCase()}/schedule">${this.generalService.getShortName(match.MatchData.Sport)} predictions</a> and the <a href="https://www.dimers.com/best-bets/${match.MatchData.Sport.toLowerCase()}">best ${this.generalService.getShortName(match.MatchData.Sport)} bets</a> today, while our in-depth analysis includes <a href="https://www.dimers.com/best-props/${match.MatchData.Sport.toLowerCase()}">${this.generalService.getShortName(match.MatchData.Sport)} props</a>, <a href="https://www.dimers.com/futures/${match.MatchData.Sport.toLowerCase()}">${this.generalService.getShortName(match.MatchData.Sport)} futures</a>, <a href="https://www.dimers.com/${match.MatchData.Sport.toLowerCase()}/best-odds">${this.generalService.getShortName(match.MatchData.Sport)} odds</a>, and the latest <a href="https://www.dimers.com/news?sport=${match.MatchData.Sport.toLowerCase()}">${this.generalService.getShortName(match.MatchData.Sport)} news</a>.</p>
                            ` : `
                                <p>For further insights, explore our expert <a href="https://www.dimers.com/bet-hub/${match.MatchData.Sport.toLowerCase()}/schedule">${this.generalService.getShortName(match.MatchData.Sport)} predictions</a>, the <a href="https://www.dimers.com/best-bets/${match.MatchData.Sport.toLowerCase()}">best ${this.generalService.getShortName(match.MatchData.Sport)} bets</a> today, and the latest <a href="https://www.dimers.com/news?sport=${match.MatchData.Sport.toLowerCase()}">${this.generalService.getShortName(match.MatchData.Sport)} news</a>.</p>
                            `}
						`;
					}


					return {
						title: title,
						match_id: match.MatchData.SIMatchID,
						match_header: 
                            match.MatchData.Sport === "SOO"
                            ? `${matchName} Game ${match.MatchData.RoundNumber}` 
                            :  match.MatchData.Sport === "NFL"
                            ? `${match_header}`
                            : `${matchName} Prediction, Picks and Odds`,

						match_subheading_title: match_subheading_title || null,
						match_subheader: match_subheader || `<strong>Predictions</strong> and <strong>picks</strong> for ${matchName} on ${format(parseJSON(match.MatchData.Date), "iii MMM d, yyyy")}, including <a routerLink='/best-bets'>best bets</a>, <strong>betting odds</strong> and <a routerLink='/live-now'>live updates</a>.`,
						match_subheading_description: match_subheading_description || null,
						default_description: description,
						home_team_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last}` : match.MatchData.HomeTeam.DisplayName,
						visiting_team_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}` : match.MatchData.AwayTeam.DisplayName,
                        home_team_nick_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player1.nameDetails.first} ${match.MatchData.PlayerData.player1.nameDetails.last}` : this.generalService.teamNameDisplay(match.MatchData.HomeTeam),
						visiting_team_nick_name: match.MatchData.Sport === "TEN" ? `${match.MatchData.PlayerData.player2.nameDetails.first} ${match.MatchData.PlayerData.player2.nameDetails.last}` : this.generalService.teamNameDisplay(match.MatchData.AwayTeam),
						match_date: match.MatchData.Date,
						venue: match.MatchData.Sport === "TEN" ? match.MatchData.TournamentName : match.MatchData.Venue,
						description: description,
						// thumbnail: {url: "https://cdn.ciphersports.io/images/generic_match_page_meta.jpg"},
						// thumbnail: {url: environment.defaultMetaImage},
						faqs: faqs,
					}
				})
			)
		
		

	}

	bestBetText(bet: MatchBet, match: Match): string {
		if (bet.type === "line") {
			if (match.MatchData.Sport.toLowerCase() !== "ten") {
				return `${this.generalService.teamNameDisplay(bet.bet === 'home' ? match.MatchData.HomeTeam : match.MatchData.AwayTeam)} ${bet.lineValue >= 0 ? '+' : ''}${bet.lineValue.toFixed(1)}`
			} else {
				return `${this.matchService.minUniqueName(match, bet.bet === 'home' ? 1 : 2)} ${bet.lineValue >= 0 ? '+' : ''}${bet.lineValue.toFixed(1)}`
			}
		}
		
		if (bet.type === "total") {
			return `${bet.bet === 'over' ? 'Over' : 'Under'} ${bet.markValue}`
		}

		if (bet.type === "h2h") {
			if (match.MatchData.Sport.toLowerCase() !== "ten") {
				return `${this.generalService.teamNameDisplay(bet.bet === 'home' ? match.MatchData.HomeTeam : match.MatchData.AwayTeam)} win`
			} else {
				return `${this.matchService.minUniqueName(match, bet.bet === 'home' ? 1 : 2)} win`
			}
		}
	
		if (bet.type === "firstset") {
			return `${this.matchService.minUniqueName(match, bet.bet === 'home' ? 1 : 2)} win 1st Set`
		}
	}

	getBestOddsMeta(sportCode: string): Observable<PageMetaObject> {
		return this.getAppSettings(`${sportCode.toLowerCase()}-best-odds`);
	}

	getNewsMeta(sportCode: string): Observable<Record<string, any>> {
		return this.getAppSettings(sportCode.toLowerCase() === "all" ? "news" : `${sportCode.toLowerCase()}-news`);
	}

	getFuturesMeta(sportCode: string): Observable<PageMetaObject> {
		return this.getAppSettings(`${sportCode.toLowerCase()}-futures`);
	}
	// this is for sport-specific version of best bets page 
	getSportBestBetsMeta(sportCode: string): Observable<PageMetaObject> {
		return this.getAppSettings(`${sportCode.toLowerCase()}-best-bets`);
	}

	// this is for sport-specific version of best props page 
	getSportBestPropsMeta(sportCode: string): Observable<PageMetaObject> {
		return this.getAppSettings(`${sportCode.toLowerCase()}-best-props`);
	}

    getPlayerProjectionMeta(sportCode: string):Observable<PageMetaObject> {
		return this.getAppSettings(`${sportCode.toLowerCase()}-player-projection`);
	}
	// getBestBooksData(stateCode?: string): Observable<Array<Record<string, any>>> {
	// 	return this.http.get<any>(stateCode ? `${environment.dimersApiDomain}/api/v1/bookmakers/location-order?state=${stateCode}` : `${environment.dimersApiDomain}/api/v1/bookmakers`)
	// 	.pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getBestBooksData"}}),
	// 		map((response: any) => {
	// 			if (response.data) {
	// 				return (response.data);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Record<string, any>>())
	// 	)
	// }

	getBookmakerData(bookmakerCode: string, stateCode?: string): Observable<Record<string, any>> {
		return this.http.get<any>(stateCode ? `${environment.dimersApiDomain}/api/v1/bookmakers/${bookmakerCode}?state=${stateCode}` : `${environment.dimersApiDomain}/api/v1/bookmakers/${bookmakerCode}`)
		.pipe(
			// timeout({first: 5000, meta: {methodName: "getBookmakerData"}}),
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getBookmakerPromo(id: string): Observable<Record<string, any>> {
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/promo?filter[id]=${id}`)
		.pipe(
			// timeout({first: 5000, meta: {methodName: "getBookmakerPromo"}}),
			map((response: any) => {
				if (response.data && response.data.length > 0) {
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getHyperlink(id: string): Observable<Record<string, any>> {
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/hyperlinks/${id}`)
		.pipe(
			// timeout({first: 5000, meta: {methodName: "getHyperlink"}}),
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getPodcastInformation(): Observable<PageMetaObject> {
		return this.getAppSettings("podcasts");
	}

	getPodcastList(): Observable<Array<Record<string, any>>> {
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/app-podcasts/podcast?sort=custom_order_index`)
		.pipe(
			// timeout({first: 5000, meta: {methodName: "getPodcastList"}}),
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	

	getFreeToPlayData(): Observable<Record<string, any>> {
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/free-to-play`)
		.pipe(
			// timeout({first: 5000, meta: {methodName: "getFreeToPlayData"}}),
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	addNewsletterEmail(email: string, firstName?: string): Observable<boolean> {
		// console.log('addNewsletterEmail()');
		return this.http.post(`https://services.dimers.com/v1/visionsix/813123`, {
			email: email,
			first_name: firstName,
		})
		.pipe(
			map((response: any) => {
				if (response.subscribed?.email) {
					return true;
				} else if (response.errors) {	
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<boolean>())
		)
	}

    discountCodeEmail(email: string, firstName?: string): Observable<boolean> {
		// console.log('addNewsletterEmail()');
		return this.http.post(`https://services.dimers.com/v1/visionsix/902370`, {
			email: email,
			first_name: firstName,
		})
		.pipe(
			map((response: any) => {
				if (response.subscribed?.email) {
					return true;
				} else if (response.errors) {	
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<boolean>())
		)
	}

    addEmailForSubscription(email: string, name?: string, selectedPlan?:string, time?:string): Observable<boolean> {
        let API = "https://services.dimers.com/v1/visionsix/823496";
        if(selectedPlan === "ai") 
        {
            API ="https://services.dimers.com/v1/visionsix/859161"
        }
        
		return this.http.post(API, {
			email: email,
			name: name,
            plan: time
		})
		.pipe(
			map((response: any) => {
				if (response.subscribed?.email) {
					return true;
				} else if (response.errors) {	
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<boolean>())
		)
	}

	// getBettingExplainedCategories(): Observable<Array<SportsBetting101Category>> {

    //     const query = {
    //         "type": "sports-betting-101"
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getBettingExplainedCategories"}}),
    //         map(data => {
    //             const rawArticles: Array<Record<string, any>> = data.objects;

	// 			const allRawCategories = rawArticles.mergeMap(a => a.metadata.category);

	// 			const rawCategories = [];

	// 			allRawCategories.forEach(c => {
	// 				if (!rawCategories.some(cat => cat.slug === c.slug)) {
	// 					rawCategories.push(c);
	// 				}
	// 			})

	// 			return rawCategories.map(c => ({
	// 				name: c.title,
	// 				slug: c.slug,
	// 				created_at: null,
	// 				deleted_at: null,
	// 				description: c.content,
	// 				id: c.id,
	// 				social_summary_description: null,
	// 				social_summary_title: null,
	// 				social_thumbnail: null,
	// 				updated_at: null,

	// 				sport_betting_articles: rawArticles.filter(a => a.metadata?.category?.slug === c.slug)
	// 					.map(a => {
	// 						return this.convertSportsBetting101Article(a);
	// 					})
	// 			} as SportsBetting101Category));
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError<Array<SportsBetting101Category>>())
    //     );
		

	// }

	// getBettingExplainedCategory(slug: string): Observable<SportsBetting101Category> {

	// 	let rawCategory: Record<string, any>;
		
    //     const categoryQuery = {
    //         "type": "sports-betting-101-categories",
	// 		"slug": slug,
    //     };

    //     const categoryOptions = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(categoryQuery) )
    //         .set("props", "id,slug,title,content,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: categoryOptions}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getBettingExplainedCategory"}}),
	// 		mergeMap(categoryData => {
	// 			if (categoryData.objects?.length) {
	// 				rawCategory = categoryData.objects[0];

	// 				const articlesQuery = {
	// 					"type": "sports-betting-101",
	// 					"metadata.category": rawCategory.id,
	// 				};
			
	// 				const articlesOptions = new HttpParams()
	// 					.set("read_key", this.cosmicReadKey)
	// 					.set("query", JSON.stringify(articlesQuery) )
	// 					.set("props", "slug,title,content,metadata")
	// 					.set("depth" , 1);

	// 					return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: articlesOptions})
	// 			} else if (categoryData.errors) {
	// 				throw new Error(categoryData.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
    //         map(data => {
    //             const rawArticles: Array<Record<string, any>> = data.objects;

	// 			return {
	// 				name: rawCategory.title,
	// 				slug: rawCategory.slug,
	// 				created_at: null,
	// 				deleted_at: null,
	// 				description: rawCategory.content,
	// 				id: rawCategory.id,
	// 				social_summary_description: null,
	// 				social_summary_title: null,
	// 				social_thumbnail: null,
	// 				updated_at: null,

	// 				sport_betting_articles: rawArticles
	// 					.map(a => {
	// 						return this.convertSportsBetting101Article(a);
	// 					})
	// 			} as SportsBetting101Category;
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError<SportsBetting101Category>())
    //     );

	// }

	// getMatchPagePromos(matchID: string, stateCode: string): Observable<Array<Record<string, any>>> {
	// 	if (!matchID.includes("_")) {
	// 		return of([]);
	// 	}
		
	// 	matchID = matchID.toLowerCase();
	// 	const sportCode = matchID.split("_")[0];
	// 	return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/bespoke-promo?filter[match_id]=${matchID}&filter[tagContaining]=${sportCode}&state=${stateCode}`)
	// 	.pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getMatchPagePromos"}}),
	// 		map((response: any) => {
	// 			if (response.data) {
	// 				return (response.data);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Array<Record<string, any>>>())
	// 	)
	// }

	// getBettingExplainedArticle(slug: string): Observable<SportsBetting101Article> {

	// 	const query = {
    //         "type": "sports-betting-101",
	// 		slug: slug,
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata,published_at,content")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getBettingExplainedArticle"}}),
    //         map(data => {
	// 			if (data.objects?.length) {
	// 				const rawArticle = data.objects[0];
				
	// 				return this.convertSportsBetting101Article(rawArticle);
	// 			} else if (data.errors) {
	// 				throw new Error(data.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError<SportsBetting101Article>())
    //     );

	// }

	// private convertSportsBetting101Article(rawArticle: Record<string, any>): SportsBetting101Article {
	// 	return {
	// 		// TODO deal with autocon case
	// 		article_category: "dimers_content" as "dimers_content",
	// 		author: rawArticle.metadata.author.title,
	// 		authors: [{
	// 			id: rawArticle.metadata.author.id,
	// 			last_name: (rawArticle.metadata.author.metadata.author_first_name && rawArticle.metadata.author.title.includes(rawArticle.metadata.author.metadata.author_first_name)) ? rawArticle.metadata.author.title.replace(rawArticle.metadata.author.metadata.author_first_name, "").trim() : rawArticle.metadata.author.title,
	// 			slug: rawArticle.metadata.author.metadata.appetiser_slug,
	// 			first_name: (rawArticle.metadata.author.metadata.author_first_name && rawArticle.metadata.author.title.includes(rawArticle.metadata.author.metadata.author_first_name)) ? rawArticle.metadata.author.metadata.author_first_name : "",
	// 			social_username: rawArticle.metadata.author.metadata.twitter_username,
	// 			description: rawArticle.metadata.author.content,
	// 			short_bio: rawArticle.metadata.author.metadata.short_bio || rawArticle.metadata.author.content,
	// 			social_summary_title: undefined,
	// 			social_summary_description: undefined,
	// 			job_title: rawArticle.metadata.author.metadata.title,
	// 			years_of_experience: rawArticle.metadata.author.metadata.years_of_experience,
	// 			linkedin_link: rawArticle.metadata.author.metadata.linkedin_link,
	// 			same_as: rawArticle.metadata.author.metadata.same_as || [],
	// 			thumbnail: {
	// 				dynamic_url: rawArticle.metadata.author.metadata.author_thumbnail.imgix_url,
	// 			},
	// 		}],
	// 		content_description: rawArticle.content,
	// 		created_at: null,
	// 		featured_article: null,
	// 		id: rawArticle.id,
	// 		published_date: rawArticle.published_at,
	// 		published_date_readable: null,
	// 		unpublished_at: rawArticle.unpublish_at,
	// 		short_title: rawArticle.short_title || rawArticle.title,
	// 		slug: rawArticle.slug,
	// 		socialThumbnail: null,
	// 		social_summary_description: rawArticle.metadata.preview_text,
	// 		social_summary_title: rawArticle.social_summary_title || rawArticle.title,
	// 		sport_betting_category: rawArticle.metadata.category,
	// 		summarized_description: rawArticle.metadata.preview_text,
	// 		subtitle: rawArticle.metadata.subtitle,
	// 		tags: [],
	// 		thumbnail: {
	// 			dynamic_url: rawArticle.metadata.hero_image?.imgix_url,
	// 			url: rawArticle.metadata.hero_image?.url,
	// 		},
	// 		title: rawArticle.title,
	// 		faqs: [],
	// 		is_trending: false,
	// 		category_id: rawArticle.metadata.category.id,
	// 		show_faq: false,
	// 		article_type: "sb101",
	// 		author_thumbnail: null,
	// 		social_thumbnail: null,
	// 		author_description: rawArticle.metadata.author.content
	// 	} as SportsBetting101Article;
	// }

	// getOnboardingBookmakers(): Observable<Array<Record<string, any>>> {
	// 	return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers?filter[is_onboarding]=true&sort=onboarding_sort_index&limit=all`)
	// 	.pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getOnboardingBookmakers"}}),
	// 		map((response: any) => {
	// 			if (response.data) {
	// 				return (response.data);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Array<Record<string, any>>>())
	// 	)
	// }

	// searchBettingExplainedArticles(searchTerm: string): Observable<Array<SportsBetting101Article>> {
	// 	const query = {
    //         "type": "sports-betting-101",
	// 		"$or": searchTerm ? [
	// 			{"title": {"$regex": searchTerm, "$options": "i"}},
	// 			{"content": {"$regex": searchTerm, "$options": "i"}}
	// 		] : undefined,
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "searchBettingExplainedArticles"}}),
    //         map(data => {
    //             const rawArticles: Array<Record<string, any>> = data.objects;

	// 			return rawArticles
	// 				.map(a => {
	// 					return this.convertSportsBetting101Article(a);
	// 				});
	// 		}),
    //         shareReplay(1),
    //         catchError(this.handleError<Array<SportsBetting101Article>>())
    //     );
	// }

	sessionIncrementer = 1;

	getIncrement(): number {
		this.sessionIncrementer++;
		return this.sessionIncrementer;
	}

	// getSportsBettingPoll(id: string): Observable<Record<string, any>> {
	// 	return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/poll/${id}`)
	// 	.pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getSportsBettingPoll"}}),
	// 		map((response: any) => {
	// 			if (response.data) {
	// 				return (response.data);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Record<string, any>>())
	// 	)
	// }

	getResponsiveBanner(id: number): Observable<Record<string, any>> {
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/responsive-banners/${id}`)
		.pipe(
			// timeout({first: 5000, meta: {methodName: "getResponsiveBanner"}}),
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}


    getStateInfo(state: string): Observable<Record<string, any>>{
        // let id = this.transferState.get(STATEID_KEY, null as any);
        // let storedState = this.transferState.get(STATE_KEY, null as any);

        // if(id && state === storedState) return of(id);

        const query = {
            "type": "regions",
            "slug": state
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "id,metadata.growthleads_betting_page_path")
			.set("depth", 2);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', {params: options})
        .pipe(
			// timeout({first: 5000, meta: {methodName: "getStateInfo"}}),
            map(data => {
                // if (!this.browserMode) {
                //     this.transferState.set(STATEID_KEY, (data as any).objects[0].id as any);
                //   }
                return (data as any).objects[0];
            }),
            catchError(() => {
				return of({id: this.backupStateID, metadata: {growthleads_betting_page_path: null}});
			})
        );
    }

	getAllBestBooksPages() {
		const query = {
			"type": "regions",
			"metadata.growthleads_betting_page_path": {
				"$ne": ""
			}
		};

		const options = new HttpParams()
			.set("read_key", this.cosmicReadKey)
			.set("query", JSON.stringify(query))
			.set("props", "slug,metadata.abbreviation,metadata.growthleads_betting_page_path")
			.set("depth" , 2);

		return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
			timeout({first: 5000, meta: {methodName: "getAllBestBooksPages"}}),
			map(data => {
				return data.objects;
			}),
			shareReplay(1),
			catchError(this.handleError())
		);
	}


    // getBestBooksById(stateId: string, state: string) {
    //     // let bestbooks = this.transferState.get(BESTBOOKS_KEY, null as any);
    //     // let storedState = this.transferState.get(STATE_KEY, null as any);


    //     // if(bestbooks && state === storedState ) return of(bestbooks);

    //     const query = {
    //         "type": "dimers-best-book-details",
    //         'metadata.state': stateId
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query))
    //         .set("props", "slug,title,metadata,content")
    //         .set("depth" , 2);

    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getBestBooksById"}}),
    //         map(data => {
    //             // if (!this.browserMode) {
    //             //     this.transferState.set(BESTBOOKS_KEY, data.objects[0] as any);
    //             //     this.transferState.set(STATE_KEY, state as any);
    //             // }
    //             return data.objects[0];
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError())
    //     );
    // }

    getSportsBooks(): Observable<Array<Record<string, any>>> {

        // const query = {
        //     "type": "sportsbooks",
		// 	"metadata.atad_clients": "67cfaef40ed056126d2b27ae"
        // };

        // const options = new HttpParams()
        //     .set("read_key", this.cosmicReadKey)
        //     .set("query", JSON.stringify(query))
        //     .set("props", "slug,metadata.promos_small_logo.imgix_url,title")
        //     .set("depth" , 1);

        // return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
		// 	// timeout({first: 5000, meta: {methodName: "getSportsBooks"}}),
		// 	map(data => {
		// 		if (!this.browserMode) {
		// 			this.transferState.set(this.SPORTSBOOKS_KEY, data.objects as any);
		// 		}
		// 		return data.objects;
		// 	}),
        //     shareReplay(1),
        //     catchError(this.handleError())
        // );
		return of([
			{
				"slug": "jackpot-city",
				"title": "Jackpot City",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/545f6310-fb69-11ef-839e-d7661fd7c267-JPC-Black-and-White.png"
					}
				}
			},
			{
				"slug": "golden-nugget-casino",
				"title": "Golden Nugget",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/a96d5d90-fa96-11ef-a49a-0d512f17bfbf-gn-casino-logo-1024x1024-black-circle.png"
					}
				}
			},
			{
				"slug": "kalshi",
				"title": "Kalshi",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/b64b50c0-e43a-11ef-8a08-45f0b00f8d1d-Kalshi-Full---1080-x-1080---Black.png"
					}
				}
			},
			{
				"slug": "betr",
				"title": "BetR",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/e91aa290-cea4-11ef-ac4c-9fb95c248ff6-SymbolPrimaryLight.png"
					}
				}
			},
			{
				"slug": "draftkings-fantasy",
				"title": "DraftKings Fantasy",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/d8d58320-7a92-11ef-9e22-210e6365981c-draftkings-icon-light.png"
					}
				}
			},
			{
				"slug": "sports-interaction",
				"title": "Sports Interaction (Ontario)",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/20e2df50-4dc5-11ef-926f-211de7a312da-Copy-of-SILogoStackedSmallColour.png"
					}
				}
			},
			{
				"slug": "powerplay-sports",
				"title": "Power Play (Ontario)",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/451aba80-6339-11ef-b164-a75e20f7a9e0-PowerPlay-logo.svg"
					}
				}
			},
			{
				"slug": "proline-canada",
				"title": "PROLINE+ (Ontario)",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/0bda3340-f9de-11ef-a291-bf0fb2eccacf-Image-1.png"
					}
				}
			},
			{
				"slug": "pointsbet-canada",
				"title": "Pointsbet (Ontario)",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/98ab8830-4861-11ee-89ab-17371fc03105-PointsBet-icon.svg"
					}
				}
			},
			{
				"slug": "jackpot",
				"title": "Jackpot",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/89892180-c72f-11ee-842a-c5a5e735fba3-JACKPOT2.jpg"
					}
				}
			},
			{
				"slug": "fanduel",
				"title": "FanDuel",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg"
					}
				}
			},
			{
				"slug": "draftkings",
				"title": "DraftKings",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg"
					}
				}
			},
			{
				"slug": "bet365",
				"title": "Bet365",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg"
					}
				}
			},
			{
				"slug": "betmgm",
				"title": "BetMGM",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg"
					}
				}
			},
			{
				"slug": "betano",
				"title": "Betano (Ontario)",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/0dbfce20-2993-11ef-934b-afba1a192133-betano-dark3.png"
					}
				}
			},
			{
				"slug": "espn-bet",
				"title": "ESPN Bet",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/6e6ceea0-b66c-11ee-a3dc-c10230cabb08-ESPNBet-icon.png"
					}
				}
			},
			{
				"slug": "hardrock-bet",
				"title": "Hard Rock Bet",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/36d8f1c0-b66a-11ee-a3dc-c10230cabb08-HardRockBet-icon.png"
					}
				}
			},
			{
				"slug": "caesars",
				"title": "Caesars Sportsbook",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/46cc55a0-511a-11ee-a636-f9a0cdd9a170-Caesars-icon.png"
					}
				}
			},
			{
				"slug": "fanatics",
				"title": "Fanatics Sportsbook",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/93a76b50-b66c-11ee-a3dc-c10230cabb08-Fanatics-icon.png"
					}
				}
			},
			{
				"slug": "parlay-play",
				"title": "Parlay Play",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/065b4070-678c-11ee-bd7e-bf4f24fb39b1-ParlayPlay-icon-1.png"
					}
				}
			},
			{
				"slug": "dabble-fantasy",
				"title": "Dabble Fantasy",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/643cc8a0-be6a-11ee-8229-0f62eab2dd82-Dabble-icon.png"
					}
				}
			},
			{
				"slug": "underdog-fantasy",
				"title": "Underdog Fantasy",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/439556f0-c520-11ee-8a2c-19f2644627ae-Main-Logo-1.png"
					}
				}
			},
			{
				"slug": "ownersbox",
				"title": "OwnersBox Fantasy",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/d9f202b0-678d-11ee-bd7e-bf4f24fb39b1-OwnersBox-icon-1.png"
					}
				}
			},
			{
				"slug": "fanduel-fantasy",
				"title": "FanDuel Fantasy",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/b18eebb0-5119-11ee-a636-f9a0cdd9a170-FanDuelSportsbook-icon.png"
					}
				}
			},
			{
				"slug": "prizepicks",
				"title": "PrizePicks",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/a551d560-5119-11ee-a636-f9a0cdd9a170-PrizePicks-icon.png"
					}
				}
			},
			{
				"slug": "betrivers",
				"title": "BetRivers",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/0aa6f9f0-116c-11ef-911e-9f1d49670742-BetRivers-Small.png"
					}
				}
			},

			{
				"slug": "horseshoe",
				"title": "Horseshoe",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/4774cc90-fa96-11ef-a49a-0d512f17bfbf-Horseshoe-Online-Casino-Logo-Square.avif"
					}
				}
			},
			{
				"slug": "betvictor-canada",
				"title": "BetVictor (Ontario)",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/d2938d70-42f9-11ef-91d0-7d47a19fb22e-BetVictor-Small.svg"
					}
				}
			},
			{
				"slug": "draftkings-pick-six",
				"title": "DraftKings Pick6",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/1f76d5e0-64b3-11ef-81cc-bdfc4d6f451d-P6AppIconDev.png"
					}
				}
			},
			{
				"slug": "bn-fantasy",
				"title": "BN Fantasy",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/1928c720-a6bc-11ef-9e43-f105d4cf716b-image.png"
					}
				}
			},
			{
				"slug": "sports-millions",
				"title": "Sports Millions",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/f61512a0-dd90-11ef-a4b2-998273ce3164-sportsmillions-logo3.png"
					}
				}
			},
			{
				"slug": "underdog-fantasy-0e9735b0-e6d4-11ee-821b-bf060495aaba",
				"title": "Underdog Sportsbook",
				"metadata": {
					"promos_small_logo": {
						"imgix_url": "https://imgix.cosmicjs.com/439556f0-c520-11ee-8a2c-19f2644627ae-Main-Logo-1.png"
					}
				}
			},
		]);
    }

    getSportsBooksData(stateId: string) {
        const query = {
            "type": "sportsbooks",
            'metadata.atad_clients':'67cfaef40ed056126d2b27ae',
            'metadata.states': stateId
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query))
            .set("props", "slug")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
			// timeout({first: 5000, meta: {methodName: "getSportsBooksData"}}),
            map(data => {
                return data.objects;
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getSportsBooksById(stateId: string) {
        const query = {
            "type": "sportsbooks",
            'metadata.states': stateId
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query))
            .set("props", "slug,metadata.promos_small_logo.imgix_url")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
			// timeout({first: 5000, meta: {methodName: "getSportsBooksById"}}),
            map(data => {
                return data.objects;
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getOddsSportsbookData(stateId?:string){
        const query = {
            "type": "sportsbooks",
            'metadata.states': stateId
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query))
            .set("props", "slug,title, metadata.promo_logo, metadata.promos_small_logo")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
            map(data => {
                return data.objects;
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }
    getBestBooksPromos(stateId: string, state: string) {
        // let promos = this.transferState.get(PROMOS_KEY, null as any);
        // let storedState = this.transferState.get(STATE_KEY, null as any);

        // if(promos && state === storedState) return of(promos);

        const query = {
            "type": "sportsbook-promos",
            '$and':[
                {
                'metadata.state':stateId
                },
                {
                'metadata.atad_clients':'67cfaef40ed056126d2b27ae'
                }
            ]
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "metadata.promos_button_text,metadata.promos_disclaimer,metadata.promos_link,"
				+"metadata.promos_steps,metadata.promos_title,metadata.sportsbook.metadata.growthleads_review_path,"
				+"metadata.sportsbook.metadata.dark_bg_logo.imgix_url,"
				+"metadata.sportsbook.metadata.hex_color,"
				+"metadata.sportsbook.slug,metadata.tags.metadata")
            .set("depth" , 1);


        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
			// timeout({first: 5000, meta: {methodName: "getBestBooksPromos"}}),
            map(data => {
                // if (!this.browserMode) {
                //     this.transferState.set(PROMOS_KEY, data.objects as any);
                //     this.transferState.set(STATE_KEY, state as any);
                // }
                return data.objects;
            }),
            shareReplay(1),
            catchError(error => {
                if (error.status === 404) {
                    return of(null); // Return empty data for 404 errors
                }
                  throw error;
            })
        );
    }

    getCosmicOffersByStateId(stateId: string, placementLoc?: string){
        const query = {
            "type": "sportsbook-promos",
            '$and': [
                {
                    'metadata.state': stateId
                },
                {
                    'metadata.atad_clients': '67cfaef40ed056126d2b27ae'
                },
                {
                    'metadata.placement_locations': placementLoc
                }
            ]
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "metadata.index,metadata.promos_bonus.currency_code,metadata.promos_bonus.value,"
				+"metadata.promos_button_text,metadata.promos_disclaimer,metadata.promos_link,"
				+"metadata.promos_steps,metadata.promos_title,metadata.sportsbook.id,"
				+"metadata.sportsbook.metadata.best_for,metadata.sportsbook.metadata.growthleads_review_path,"
				+"metadata.sportsbook.metadata.dark_bg_logo.imgix_url,"
				+"metadata.sportsbook.metadata.hex_color,metadata.sportsbook.metadata.index,"
				+"metadata.sportsbook.metadata.promos_small_logo.imgix_url,metadata.sportsbook.metadata.rating,"
				+"metadata.sportsbook.slug,metadata.sportsbook.title,"
				+"metadata.tags.metadata,slug")
            .set("depth" , 1);


        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
			// timeout({first: 5000, meta: {methodName: "getCosmicOffersByStateId"}}),
            map(data => {
                return data.objects;
            }),
            shareReplay(1),
            catchError(error => {
                if (error.status === 404) {
                    return of(null); // Return empty data for 404 errors
                }
                  throw error;
            })
        );
    }

    getCosmicOffersById(Id: string){

        const query = {
            "type": "sportsbook-promos",
            "id": Id
        };
        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "metadata.promos_button_text,metadata.promos_disclaimer,metadata.promos_link,"
				+"metadata.promos_steps,metadata.promos_title,metadata.sportsbook.metadata.growthleads_review_path,"
				+"metadata.sportsbook.metadata.dark_bg_logo.imgix_url,"
				+"metadata.sportsbook.metadata.hex_color,"
				+"metadata.sportsbook.slug,metadata.tags.metadata")
            .set("depth" , 1)

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
            map(data => data.objects[0]),
            shareReplay(1),
            catchError(this.handleError())
        );
    }



    // getSportsbookReview(sportsbook: string) {

    //     // let storedSportsbook = this.transferState.get(SPORTSBOOK_KEY, null as any);
    //     // let sportsbookReview = this.transferState.get(SPORTSBOOKREVIEW_KEY, null as any);

    //     // if(sportsbookReview && storedSportsbook === sportsbook) return of(sportsbookReview);

    //     let slug = sportsbook + '-sportsbook-review';
    //     const query = {
    //         "type": "dimers-sportsbook-review-details",
    //         "slug": slug
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getSportsbookReview"}}),
    //         map(data => {
    //             // if (!this.browserMode) {
    //             //     this.transferState.set(SPORTSBOOKREVIEW_KEY, data.objects[0] as any);
    //             //     this.transferState.set(SPORTSBOOK_KEY, sportsbook as any);
    //             // }
    //             return data.objects[0]
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError())
    //     );
    // }

    // getSDfsReview(sportsbook: string) {

    //     // let storedDfs = this.transferState.get(DFS_KEY, null as any);
    //     // let dfsReview = this.transferState.get(DFSREVIEW_KEY, null as any);

    //     // if(dfsReview && storedDfs === sportsbook) return of(dfsReview);

    //     let slug = sportsbook + '-dfs-review-dimers';
    //     const query = {
    //         "type": "dimers-dfs-review-details",
    //         "slug": slug
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getSDfsReview"}}),
    //         map(data => {
    //             // if (!this.browserMode) {
    //             //     this.transferState.set(DFSREVIEW_KEY, data.objects[0] as any);
    //             //     this.transferState.set(DFS_KEY, sportsbook as any);
    //             // }
    //             return data.objects[0]
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError())
    //     );
    // }

    // sportsBookReviewLinks$: Observable<Array<Record<string, any>>> = this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects',
	// 	{
	// 		params: new HttpParams()
	// 		.set("read_key", this.cosmicReadKey)
	// 		.set("query", JSON.stringify({
	// 			"type": "dimers-sportsbook-review-details",
	// 		}))
	// 		.set("props", "metadata.sportsbook.title,metadata.sportsbook.slug, metadata.sportsbook.metadata.promos_small_logo.imgix_url")
	// 		.set("depth" , 1)
	// 	}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getSportsBookReviewLinks"}}),
	// 		map(data => {
	// 			if (!this.browserMode) {
	// 				this.transferState.set(this.REVIEWLINKS_KEY, data.objects as any);
	// 			}
	// 			return data.objects
	// 		}),
	// 		shareReplay(1),
	// 		catchError(error => {
	// 			if (error.status === 404) {
	// 				return of([]); // Return empty data for 404 errors
	// 			}
	// 				throw error;
	// 		})
	// );
    
    // sportsBookReviewLinks$: Observable<Array<Record<string, any>>> = of([ { "metadata": { "sportsbook": { "title": "bet365", "slug": "bet365", "metadata": { "promos_small_logo": { "imgix_url": "https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg" } } } } }, { "metadata": { "sportsbook": { "title": "DraftKings", "slug": "draftkings", "metadata": { "promos_small_logo": { "imgix_url": "https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg" } } } } }, { "metadata": { "sportsbook": { "title": "BetMGM", "slug": "betmgm", "metadata": { "promos_small_logo": { "imgix_url": "https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg" } } } } }, { "metadata": { "sportsbook": { "title": "FanDuel", "slug": "fanduel", "metadata": { "promos_small_logo": { "imgix_url": "https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg" } } } } }, { "metadata": { "sportsbook": { "title": "BetRivers", "slug": "betrivers", "metadata": { "promos_small_logo": { "imgix_url": "https://imgix.cosmicjs.com/98acc0b0-4861-11ee-b0ba-f39b6f0c3501-BetRivers-icon.svg" } } } } }, { "metadata": { "sportsbook": { "title": "Fanatics ", "slug": "fanatics", "metadata": { "promos_small_logo": { "imgix_url": "https://imgix.cosmicjs.com/c3e9b210-98b1-11ee-8a8d-abf95e574482-Fanatics-small.svg" } } } } }, { "metadata": { "sportsbook": { "title": "Hard Rock Bet", "slug": "hardrock-bet", "metadata": { "promos_small_logo": { "imgix_url": "https://imgix.cosmicjs.com/36d8f1c0-b66a-11ee-a3dc-c10230cabb08-HardRockBet-icon.png" } } } } }, { "metadata": { "sportsbook": { "title": "ESPN Bet", "slug": "espn-bet", "metadata": { "promos_small_logo": { "imgix_url": "https://imgix.cosmicjs.com/c402df60-98b1-11ee-8a8d-abf95e574482-EspnBet-small.svg" } } } } } ]); 



    // getGeneralPromoCodeLinks(): Observable<Array<Record<string, any>>> {

    //     return of([{"metadata":{"sportsbook":{"title":"bet365","slug":"bet365","metadata":{"promos_small_logo":{"imgix_url":"https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg"}}}}},{"metadata":{"sportsbook":{"title":"FanDuel","slug":"fanduel","metadata":{"promos_small_logo":{"imgix_url":"https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg"}}}}},{"metadata":{"sportsbook":{"title":"DraftKings","slug":"draftkings","metadata":{"promos_small_logo":{"imgix_url":"https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg"}}}}},{"metadata":{"sportsbook":{"title":"BetMGM","slug":"betmgm","metadata":{"promos_small_logo":{"imgix_url":"https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg"}}}}}]);
    //     // let storedPromoCodeLinks = this.transferState.get(this.GeneralPromoCodeLinks_KEY, null as any);
        
    //     // if (storedPromoCodeLinks) {
    //     //     return of(storedPromoCodeLinks)
    //     // }
    //     // const query = {
    //     //     "type": "dimers-individual-promo-general",
    //     // };

    //     // const options = new HttpParams()
    //     //     .set("read_key", this.cosmicReadKey)
    //     //     .set("query", JSON.stringify(query) )
    //     //     .set("props", "metadata.sportsbook.title, metadata.sportsbook.slug, metadata.sportsbook.metadata.promos_small_logo.imgix_url")
    //     //     .set("depth" , 1);

    //     // return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 	// 	// timeout({first: 5000, meta: {methodName: "getGeneralPromoCodeLinks"}}),
    //     //     map(data => {
    //     //         if (!this.browserMode) {
    //     //             this.transferState.set(this.GeneralPromoCodeLinks_KEY, data.objects as any);
    //     //         }
    //     //         return data.objects
    //     //     }),
    //     //     shareReplay(1),
    //     //     catchError(error => {
    //     //         if (error.status === 404) {
    //     //             return of([]); // Return empty data for 404 errors
    //     //         }
    //     //           throw error;
    //     //     })
    //     // );
    // }


    // dfsReviewLinks$: Observable<Array<Record<string, any>>> = this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {
	// 		params: new HttpParams()
	// 			.set("read_key", this.cosmicReadKey)
	// 			.set("query", JSON.stringify({
	// 				"type": "dimers-dfs-review-details",
	// 			}))
	// 			.set("props", "metadata.sportsbook.title,metadata.sportsbook.slug, metadata.sportsbook.metadata.promos_small_logo.imgix_url")
	// 			.set("depth" , 1)
	// 	}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getDFSReviewLinks"}}),
	// 		map(data => {
	// 			if (!this.browserMode) {
	// 				this.transferState.set(this.DFSREVIEWLINKS_KEY, data.objects as any);
	// 			}
	// 			return data.objects
	// 		}),
	// 		shareReplay(1),
	// 		catchError(error => {
	// 			if (error.status === 404) {
	// 				return of([]); // Return empty data for 404 errors
	// 			}
	// 			throw error;
	// 		})
    //     );
    // dfsReviewLinks$ = of([ { metadata: { sportsbook: { title: "Dabble Fantasy", slug: "dabble-fantasy", metadata: { promos_small_logo: { imgix_url: "https://imgix.cosmicjs.com/643cc8a0-be6a-11ee-8229-0f62eab2dd82-Dabble-icon.png", }, }, }, }, }, { metadata: { sportsbook: { title: "Underdog Fantasy", slug: "underdog-fantasy", metadata: { promos_small_logo: { imgix_url: "https://imgix.cosmicjs.com/439556f0-c520-11ee-8a2c-19f2644627ae-Main-Logo-1.png", }, }, }, }, }, { metadata: { sportsbook: { title: "Pick6", slug: "draftkings-pick-six", metadata: { promos_small_logo: { imgix_url: "https://imgix.cosmicjs.com/1f76d5e0-64b3-11ef-81cc-bdfc4d6f451d-P6AppIconDev.png", }, }, }, }, }, { metadata: { sportsbook: { title: "Parlay Play", slug: "parlay-play", metadata: { promos_small_logo: { imgix_url: "https://imgix.cosmicjs.com/065b4070-678c-11ee-bd7e-bf4f24fb39b1-ParlayPlay-icon-1.png", }, }, }, }, }, { metadata: { sportsbook: { title: "PrizePicks", slug: "prizepicks", metadata: { promos_small_logo: { imgix_url: "https://imgix.cosmicjs.com/a551d560-5119-11ee-a636-f9a0cdd9a170-PrizePicks-icon.png", }, }, }, }, }, { metadata: { sportsbook: { title: "DraftKings Fantasy", slug: "draftkings-fantasy", metadata: { promos_small_logo: { imgix_url: "https://imgix.cosmicjs.com/d8d58320-7a92-11ef-9e22-210e6365981c-draftkings-icon-light.png", }, }, }, }, } ]);

    // getSportsBooksReviewMethodology() {
    //     const query = {
    //         "type": "sportsbook-review-methodology",
    //         "slug": 'dimers-sportsbook-review-methodology'
    //     };

    //     const options = new HttpParams()
    //     .set("read_key", this.cosmicReadKey)
    //     .set("query", JSON.stringify(query) )
    //     .set("props", "slug,title,metadata,content");

    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getSportsBooksReviewMethodology"}}),
	// 		map(data => {
	// 			return data.objects[0];
	// 		}),
	// 		shareReplay(1),
	// 		catchError(this.handleError())
    //     );
    // }

    getCosmicAboutUs(){
        // let storedAboutUs = this.transferState.get(ABOUTUS_KEY, null as any);

        // if (storedAboutUs) {
        //     return of(storedAboutUs)
        // }
        const query = {
            "type": "dimers-about-us-page",
            "slug": "dimers-about-us"
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "slug,title,metadata")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
			// timeout({first: 5000, meta: {methodName: "getCosmicAboutUs"}}),
            map(data => {
                // if (!this.browserMode) {
                //     this.transferState.set(ABOUTUS_KEY, data.objects[0] as any);
                // }
                return data.objects[0]
            } ),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getCosmicSubscription(slug:string){
        const query = {
            "type": "sport-subscription-landing-pages",
            "slug": slug
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "slug,title,metadata")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
            map(data => {
                return data.objects[0]
            } ),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getCosmicEditorialGuideline():Observable<Array<Record<string, any>>>{
        // let storedData = this.transferState.get(EDITORIALGUIDE_KEY, null as any);

        // if (storedData) {
        //     return of(storedData)
        // }
        const query = {
            "type": "dimers-editorial-guideline",
            "slug": "editorial-guideline"
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "slug,title,metadata")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
			// timeout({first: 5000, meta: {methodName: "getCosmicEditorialGuidelines"}}),
            map(data => {
                // if (!this.browserMode) {
                //     this.transferState.set(EDITORIALGUIDE_KEY, data.objects[0] as any);
                // }
                return data.objects[0]
            } ),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getCosmicOurData():Observable<Array<Record<string, any>>>{
        // let storedData = this.transferState.get(OurData_KEY, null as any);

        // if (storedData) {
        //     return of(storedData)
        // }
        const query = {
            "type": "dimers-our-data",
            "slug": "our-data"
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "slug,title,metadata")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
			// timeout({first: 5000, meta: {methodName: "getCosmicOurData"}}),
            map(data => {
                // if (!this.browserMode) {
                //     this.transferState.set(OurData_KEY, data.objects[0] as any);
                // }
                return data.objects[0]
            } ),
            shareReplay(1),
            catchError(this.handleError())
        );
    }


    
    // getSportsbookPromoCode(slug: string) {

    //     // let storedSportsbookPromo = this.transferState.get(PROMOCODE_KEY, null as any);
    //     // let SportsbookPromo = this.transferState.get(SPORTSBOOKPROMO_KEY, null as any);

    //     // if(SportsbookPromo && storedSportsbookPromo === slug) return of(SportsbookPromo);

    //     const query = {
    //         "type": "dimers-individual-promo-states",
    //         "slug": slug
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getSportsbookPromoCode"}}),
    //         map(data => {
    //             // if (!this.browserMode) {
    //             //     this.transferState.set(SPORTSBOOKPROMO_KEY, data.objects[0] as any);
    //             //     this.transferState.set(PROMOCODE_KEY, slug as any);
    //             // }
    //             return data.objects[0]
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError())
    //     );
    // }

    // getGeneralSportsbookPromoCode(slug: string) {
    //     // let storedData = this.transferState.get(GeneralSportsbookPC_KEY, null as any);
    //     // let sportsbooks = this.transferState.get(GeneralPromoCodeSportsbook_KEY, null as any);
    //     // if (storedData && sportsbooks === slug  ) {
    //     //     return of(storedData)
    //     // }

    //     const query = {
    //         "type": "dimers-individual-promo-general",
    //         "slug": slug
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getGeneralSportsbookPromoCode"}}),
    //         map(data => {
    //             // if (!this.browserMode) {
    //             //     this.transferState.set(GeneralSportsbookPC_KEY, data.objects[0] as any);
    //             //     this.transferState.set(GeneralPromoCodeSportsbook_KEY, slug as any);
    //             // }
    //             return data.objects[0]
    //         }),
    //         shareReplay(1),
    //         catchError(error=> {
    //             if (error.status === 404) {
    //                 this.router.navigate(['/notfound']);
    //             }
    //               throw error
    //         })            
    //     );
    // }

    // getCanadaSportsbooksReview(slug: string) {
    //     const query = {
    //         "type": "dimers-canada-sportsbooks-review",
    //         "slug": slug
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getGeneralSportsbookPromoCode"}}),
    //         map(data => {
                
    //             return data.objects[0]
    //         }),
    //         shareReplay(1),
    //         catchError(error=> {
    //             if (error.status === 404) {
    //                 this.router.navigate(['/notfound']);
    //             }
    //               throw error;
    //         })            
    //     );
    // }

    // getStateBonus(slug: string) {

    //     // let storedStateBonus = this.transferState.get(STATEBONUS_KEY, null as any);
    //     // let object = this.transferState.get(STATEBONUSOBJECT_KEY, null as any);

    //     // if(object && storedStateBonus === slug) return of(object);

    //     const query = {
    //         "type": "dimers-best-promotions-state",
    //         "slug": slug
    //     };

    //     const options = new HttpParams()
    //         .set("read_key", this.cosmicReadKey)
    //         .set("query", JSON.stringify(query) )
    //         .set("props", "slug,title,metadata")
    //         .set("depth" , 1);


    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getStateBonus"}}),
    //         map(data => {
    //             // if (!this.browserMode) {
    //             //     this.transferState.set( STATEBONUSOBJECT_KEY, data.objects[0] as any);
    //             //     this.transferState.set( STATEBONUS_KEY, slug as any);
    //             // }
    //             return data.objects[0]
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError())
    //     );
    // }

    // getBestSportPromo(slug:string): Observable<Record<string, any>> {

    //     const query = {
    //         "type": "dimers-best-promotions-sports",
    //         "slug": slug
    //     };

    //     const options = new HttpParams()
    //     .set("read_key", this.cosmicReadKey)
    //     .set("query", JSON.stringify(query) )
    //     .set("props", "slug,title,metadata")
    //     .set("depth" , 1);

    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getBestSportPromo"}}),
    //         map(data => {
    //             return data.objects[0]
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError())
    //     );

    // }

    // getSportsBetting(slug:string): Observable<Record<string, any>> {

    //     const query = {
    //         "type": "dimers-best-betting-site-sports",
    //         "slug": slug
    //     };

    //     const options = new HttpParams()
    //     .set("read_key", this.cosmicReadKey)
    //     .set("query", JSON.stringify(query) )
    //     .set("props", "slug,title,metadata")
    //     .set("depth" , 1);

    //     return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
	// 		// timeout({first: 5000, meta: {methodName: "getSportsBetting"}}),
    //         map(data => {
    //             return data.objects[0]
    //         }),
    //         shareReplay(1),
    //         catchError(this.handleError())
    //     );

    // }

    getDimersPages(slug: string):Observable<Record<string, any>> {
        const query = {
            "type": "dimers-pages",
            "slug": slug
        };

        const options = new HttpParams()
        .set("read_key", this.cosmicReadKey)
        .set("query", JSON.stringify(query) )
        .set("props", "slug,title,metadata")
        .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
			// timeout({first: 5000, meta: {methodName: "getDimersPages"}}),
            map(data => {
                return data.objects[0]
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getSubscriptionPages(slug: string):Observable<Record<string, any>> {

        // let cachedDimersPage = this.transferState.get(this.subscriptionsKey, null as any);
        // let cachedPageSlug = this.transferState.get(this.SubspageSlugKey, null as any);

        // if(cachedDimersPage && cachedPageSlug === slug) {
        //     return of(cachedDimersPage);
        // }

        const query = {
            "type": "dimers-subscriptions",
            "slug": slug
        };

        const options = new HttpParams()
        .set("read_key", this.cosmicReadKey)
        .set("query", JSON.stringify(query) )
        .set("props", "slug,title,metadata")
        .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
			// timeout({first: 5000, meta: {methodName: "getDimersPages"}}),
            map(data => {
                // Store the data in TransferState
                // if (!this.browserMode) {
                    // this.transferState.set(this.subscriptionsKey, data.objects[0] as any);
                    // this.transferState.set(this.SubspageSlugKey, slug as any);
                // }
                if(data.status === 404) {
                    return null;
                }
                return data.objects[0]
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getCosmicPodcasts(): Observable<Record<string, any>>{
        
        const query = {
            "type": "dimers-podcasts",
            "slug": "dimers-podcasts-landing-page"
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "slug,title,metadata")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
            map(data => {
                return data.objects[0]
            } ),
            shareReplay(1),
            catchError(this.handleError())
        );
    }


    manageStripeSubscriptions(userId):Observable<string> {
        return this.http.post<any>(`${environment.dimersProApi}/manage`,
        {
            "customer_id": userId,
            "return_url": window.location.origin + "/my-account"
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
        ).pipe(
            map(response => response.url),
            catchError(this.handleError()));
    }

    getcustomerStripeSubscriptionId(customerId):Observable<string> {
        return this.http.get<any>(`${environment.dimersProApi}/${customerId}/info`,).pipe(
            map(response => response[0].id),
            catchError(this.handleError()));
    }

    deleteAuth0Account(userId:string):Observable<any> {
        return this.http.delete(encodeURI(`${environment.dimersProApi}/${userId}`))
            .pipe(
                catchError(this.handleError())
            )
    }

    createTicketInZoho(subject:string, email:string, description:string, userType:string, name?:string, company?:string, formType?:string): Observable<any>{
        const pageLocation = this.router.url === '/' ? 'home' : this.router.url;
    
        return this.http.post("https://contact.hypometertechnologies.com/api/contact/zoho/create_ticket",
            {
                "category": userType,
                "subject": subject,
                "email": email,
                "description": description,
                "cf_name": name? name:"null",
                "cf_company": company? company:"null",
                "cf_form_type": formType,
                "cf_location": pageLocation 
            }, {
                headers: {
                    "dev_mode": "development"
                }
            })
        .pipe(
            catchError(this.handleError())
        )
    }

    bestBetEducationData$:Observable<Array<Record<string, any>>> =  this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects',{ params:{
        read_key: this.cosmicReadKey,
        query: JSON.stringify({
            "type": "best-bet-education-page",
            "slug": "best-bet-education"
        }),
        props: "slug,title,metadata",
		depth: "2"
    }

    }).pipe(
        map(data => {
            return data.objects[0]
        }),
        shareReplay(1),
        catchError(this.handleError())
    );

    bestPropEducationData$:Observable<Array<Record<string, any>>> =  this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects',{ params:{
        read_key: this.cosmicReadKey,
        query: JSON.stringify({
            "type": "best-prop-education-page",
            "slug": "best-prop-education-page"
        }),
        props: "slug,title,metadata",
		depth: "2"
    }

    }).pipe(
        map(data => {
            return data.objects[0]
        }),
        shareReplay(1),
        catchError(this.handleError())
    );

	// timeAgoString(date: Date): string {
	// 	let hoursago = differenceInMilliseconds(date, new Date()) / (1000 * 3600) * -1;
	// 	if (hoursago < 1) {
	// 		// minutes ago
	// 		var minutesago = hoursago * 60;
	// 		return minutesago.toFixed(0) + (minutesago.toFixed(0) == "1" ? " minute ago" : " minutes ago");
	// 	} else if (hoursago < 24) {
	// 		// hours ago
	// 		return hoursago.toFixed(0) + (hoursago.toFixed(0) == "1" ? " hour ago" : " hours ago");
	// 	} else if (hoursago < (24 * 7)) {
	// 		// days ago
	// 		var daysago = hoursago / 24;
	// 		return daysago.toFixed(0) + (daysago.toFixed(0) == "1" ? " day ago" : " days ago");
	// 	} else if (isThisYear(date)) {
	// 		return format(date, "d MMMM")
	// 	} else {
	// 		return format(date, "d MMMM yyyy")
	// 	}
	// }

	convertCategorySlug(categorySlug: string): string {
		if (SPORTS.some(s => s.code === categorySlug.toUpperCase())) {
			return SPORTS.find(s => s.code === categorySlug.toUpperCase()).shortName;
		}

		return categorySlug.toUpperCase();
	}

	

	private handleError<T>() {
		return (error: any): Observable<T> =>{
			return throwError(new Error("CMS_CALL_FAILED " + JSON.stringify(error, ["message", "arguments", "type", "name", "info", "meta", "methodName"])));
		}
	}

}